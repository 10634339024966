import Vue from "vue";

export default {
  namespaced: true,
  state: {
    products: [],
    product: {},
    filters: [],
    sameProducts: [],
    asaxiyProducts: [],
    commentsData: [],
    views: 0,
    searchSuggestions: {
      categories: [],
      products: [],
    },
    units:[]
  },
  mutations: {
    SET_VIEWS_COUNTER(state, count) {
      state.views = count;
    },
    SET_UNITS(state, units){
      state.units=units
    },
    CLEAR_VIEWS_COUNTER(state) {
      state.views = 0;
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    SET_SAME_PRODUCTS(state, products) {
      state.sameProducts = products;
    },
    CLEAR_SAME_PRODUCTS(state) {
      state.sameProducts = [];
    },
    SET_ASAXIY_PRODUCTS(state, products) {
      state.asaxiyProducts = products;
    },
    CLEAR_ASAXIY_PRODUCTS(state) {
      state.asaxiyProducts = [];
    },
    CLEAR_FILTERS(state) {
      state.filters = [];
    },
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    CLEAR_PRODUCTS(state) {
      state.products = [];
    },
    SET_PRODUCT(state, product) {
      state.product = product;
    },
    SET_SEARCH_SUGGESTIONS(state, suggestions) {
      state.searchSuggestions = suggestions;
    },
    CLEAR_SEARCH_SUGGESTIONS(state) {
      state.searchSuggestions = false;
    },
    SET_COMMENTS_DATA(state, data) {
      state.commentsData = data;
    },
  },

  getters: {
    commentsLength(state) {
      return state.commentsData.length;
    },
    units(state){ 
      return state.units
    }
  },

  actions: {
    getProductsByCategory({ commit, rootState }, params) {
      const { categoryId, page, perPage, sorting, order, search, filters } = params;
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios
          .get("api/v2/sync/frontend/catalog/products", {
            params: {
              lang: rootState["Account"].account.user.locale,
              // shipping_region_id: rootState["Account"].account.token ? rootState["Account"].account.user.oblId : 0,
              // shipping_district_id: rootState["Account"].account.token ? rootState["Account"].account.user.areaId : 0,
              category_id: categoryId,
              page,
              page_size: perPage,
              sorting,
              order,
              search,
              ...filters,
            },
          })
          .then((response) => {
            commit("SET_PRODUCTS", response.data.data.products);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
      });
    },
    detail({ commit, rootState }, productId) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.get("api/v2/sync/frontend/catalog/product/detail", {
            params: {
              product_id: productId,
              lang: rootState["Account"].account.user.locale,
              region_id: rootState["Account"].account.user.oblId,
              district_id: rootState["Account"].account.user.areaId,
            },
          })
          .then((response) => {
            commit("SET_PRODUCT", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
      });
    },
    detailHistory({ commit, rootState }, productId) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.get("api/v4/sync/frontend/catalog/product/detail", {
            params: {
              product_id: productId,
              lang: rootState["Account"].account.user.locale,
              region_id: rootState["Account"].account.user.oblId,
              district_id: rootState["Account"].account.user.areaId,
            },
          })
          .then((response) => {
            commit("SET_PRODUCT", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
      });
    },
    unitsLanguages({ rootState, commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios.get("api/v1/list", {
            params: {
              lang: rootState["Account"].account.user.locale,
              type_id: 103,
            },
          })
          .then((response) => {
            commit('SET_UNITS', response.data.data)
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error.response);
          })
      });
    },
    expirationLanguages({ rootState }) {
      return new Promise((resolve, reject) => {
        Vue.axios.get("api/v1/list", {
            params: {
              lang: rootState["Account"].account.user.locale,
              type_id: 109,
            },
          })
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error.response);
          })
      });
    },
    countryLanguages({ rootState }) {
      return new Promise((resolve, reject) => {
        Vue.axios.get("api/v1/list", {
            params: {
              lang: rootState["Account"].account.user.locale,
              type_id: 30,
            },
          })
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error.response);
          })
      });
    },
    getViewsCounter({ commit }, productId) {
      commit("CLEAR_VIEWS_COUNTER");
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.get("api/v1/product/counter", {
            params: { product_id: productId },
          })
          .then((response) => {
            commit("SET_VIEWS_COUNTER", response.data.data.counter);
            resolve();
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
      });
    },
    getSameProducts({ commit, rootState }, productId) {
      commit("CLEAR_SAME_PRODUCTS");
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.get("api/v2/catalog/product/detail", {
            params: {
              product_id: productId,
              region_id: rootState["Account"].account.user.oblId,
              district_id: rootState["Account"].account.user.areaId,
            },
          })
          .then((response) => {
            commit("SET_SAME_PRODUCTS", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
      });
    },
    getAsaxiyProducts({ commit, rootState }, productId) {
      commit("CLEAR_ASAXIY_PRODUCTS"); 
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.get("api/v1/catalog/product/asaxiy/detail", {
            params: {
              product_id: productId,
              region_id: rootState["Account"].account.user.oblId,
              district_id: rootState["Account"].account.user.areaId,
            },
          })
          .then((response) => {
            commit("SET_ASAXIY_PRODUCTS", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
      });
    },
    checkDelivery(_, deliveryParams) {
      return new Promise((resolve, reject) => { 
        Vue.axios.get("api/v1/product/delivery", { params: deliveryParams, })
          .then((response) => {
            resolve(response.data.data.delivery);
          })
          .catch((error) => {
            reject(error.response);
          }) 
      });
    },
    search({ commit }, searchQuery) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.get("api/v2/search", {
            params: { q: searchQuery },
            __silent: true,
          })
          .then((response) => {
            commit("SET_SEARCH_SUGGESTIONS", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => reject(error.response))
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
      });
    },
    history_get({ commit }) { 
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.get("api/v1/user/data/history?page=1&page_size=5&action=search")
          .then((response) => {
            let data={
              categories:[],
              products:[]
            }
            
            response.data.data.forEach(element => {
              if(element.type=='category'){ 
                data.categories.push(element.type_id)
              }
              if(element.type=='product'){
                data.products.push({
                  file_id:element.image,
                  ...element
                  
                })

              }
            });
            commit("SET_SEARCH_SUGGESTIONS", data);
            resolve(data);
          })
          .catch((error) => reject(error.response))
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
      });
    },
    getFilters({ commit, rootState }, data) {
      const {categoryId, page, pageSize, search, regionId, districtId, allRegions} = data;
      commit("CLEAR_FILTERS");
      return new Promise((resolve, reject) => {
        // commit('SET_LOADING', true, {root: true})
        Vue.axios.get("api/v2/filters", {
            params: {
              lang: rootState["Account"].account.user.locale,
              region_id: regionId,
              district_id: districtId,
              category_id: categoryId,
              page,
              page_size: pageSize,
              search,
              all_regions: allRegions
            },
          })
          .then((response) => {
            commit("SET_FILTERS", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(()=>{
            // commit('SET_LOADING', false, {root: true})
          })
      });
    },
    getCommentsData({ commit }, params) {
      const { product_id, sorting, order } = params;
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.get("api/v1/product/rating", {
            params: {
              product_id,
              sorting,
              order,
            },
          })
          .then((response) => {
            commit("SET_COMMENTS_DATA", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
      });
    },
  },
};
