// import Vue from "vue";

// export default {
//   namespaced: true,
//   state: {
//     plasticCardId: null,
//     plasticCardData: [],
//     selectedCardIds: [],
//     selectLabel: "Kartadan to'lash",
//   },
//   mutations: {
//     SET_CREDIT_ID(state, id) {
//       state.plasticCardId = id;
//     },
//     SET_CREDIT_DATA(state, data) {
//       state.plasticCardData = data;
//     },
//     SET_SELECTED_CARD_DATA(state, data) {
//       state.selectedCardIds.push(data);
//     },
//     DELETE_SELECTED_CARD_DATA(state, id) {
//       state.selectedCardIds = state.selectedCardIds.filter((item) => item.id !== id);
//       const findDeletedCard = state.plasticCardData.find((item) => item.id === id);
//       if (findDeletedCard) {
//         findDeletedCard["selected"] = false;
//         if (state.selectedCardIds.length > 0) {
//           state.selectLabel = state.selectedCardIds[state.selectedCardIds.length - 1].name;
//         } else {
//           state.selectLabel = "Kartadan to'lash";
//         }
//       }
//     },
//     SET_SELECT_LABEL(state, label) {
//       state.selectLabel = label;
//     },
//     SET_PAYMENT_VALUE(state, { id, value }) {
//       const findItem = state.selectedCardIds.find((item) => item.id === id);
//       if (findItem) findItem["paymentValue"] = value;
//     },
//   },
//   getters: {},
//   actions: {
//     sendCardData({ commit }, cardInfo) {
//       return new Promise((resolve, reject) => {
//         Vue.axios
//           .post("api/v1/card", cardInfo)
//           .then((response) => {
//             commit("SET_CREDIT_ID", response.data.data.id);
//             resolve(response.data);
//           })
//           .catch((error) => {
//             reject(error.response.data);
//           });
//       });
//     },

//     getCardsData({ commit }) {
//       return new Promise((resolve, reject) => {
//         Vue.axios
//           .get("api/v1/cards")
//           .then((response) => {
//             commit("SET_CREDIT_DATA", response.data.data);
//             resolve(response.data.data);
//           })
//           .catch((error) => {
//             reject(error.response.data);
//           });
//       });
//     },

//     sendSmsCode(_, smsData) {
//       return new Promise((resolve, reject) => {
//         Vue.axios
//           .put("api/v1/cards/sms/validation", smsData)
//           .then((response) => {
//             resolve(response.data);
//           })
//           .catch((error) => {
//             reject(error.response.data);
//           });
//       });
//     },
//   },
// };

import Vue from "vue";

export default {
  namespaced: true,
  state: {
    plasticCardId: null,
    plasticCardData: [],
    selectedCardIds: {},
    selectLabel: "Kartadan to'lash",
  },
  mutations: {
    SET_CREDIT_ID(state, id) {
      state.plasticCardId = id;
    },
    SET_CREDIT_DATA(state, data) {
      state.plasticCardData = data;
    },
    SET_SELECTED_CARD_DATA(state, data) {
      state.selectedCardIds = data;
    },
    SET_SELECT_LABEL(state, label) {
      state.selectLabel = label;
    },
  },
  getters: {
    
  },
  actions: {
    sendCardData({ commit }, cardInfo) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("api/v1/card", cardInfo)
          .then((response) => {
            commit("SET_CREDIT_ID", response.data.data.id);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },

    getCardsData({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("api/v1/cards")
          .then((response) => {
            commit("SET_CREDIT_DATA", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },

    sendSmsCode(_, smsData) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .put("api/v1/cards/sms/validation", smsData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },

    sendFeedbackToProduct(_, data) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post("api/v1/product/rating", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
  },
};
