export default {
  lang: "Русский",
  interface: {
    unknown_erorr: "Незивестная ошибка: {error}",
    error_occurred: "Произошла ошибка",
    error_message:
      "Во время операции произошла ошибка. Пожалуйста, обратитесь в службу поддержки для решения данной проблемы.",
    fill_required_fields: "Заполните все отмеченные поля (*)",
    more: "Подробно",
    hide: "Свернуть",
    edit: "Изменить",
    choose: "Выбрать",
    back: "Назад",
    pagination_back:'Назад',
    forward: "Вперед",
    wishlist: "Избранное",
    remove: "Удалить",
    cancel: "Отменить",
    save: "Сохранить",
    are_you_sure: "Вы уверенны?",
    understand: "Понятно",
    search: "Поиск",
    all_search_results: "Все результаты поиска",
    avarage_costs: "Средние цены на площадке smart-market.uz",
    seen_results: "Вы посмотрели {viewedItems} результатов из {total}",
    search_undefined:"Информация, соответствующая критерию поиска, не найдена!",
    yes:"Да",
    no:"Нет"
  },
  navigation: {
    seller_cabinet: "Личный кабинет",
    orders: "Заказы",
    my_orders: "Заказы",
    my_cards: "Карты",
    notifications: "Уведомления",
    wishlist: "Избранное",
    cart: "Корзина",
    logout: "Выйти",
    documents: "Документы",
    contracts: "Договора",
    invoices: "Счет-фактуры",
    login: "Войти",
    main: "Главная",
    category: "Категория",
    profile: "Профиль",
    all_categories: "Все категории",
    search_placeholder: "Введите название товара или id номер",
    search_button: "Поиск",
    delivery_to: "Ваш адрес <span>{place}</span>",
    choose_delivery: "Выберите район/город",
    choose_delivery_message:"Выберите город/поселок, в котором вы находитесь, чтобы мы могли показывать самые близкие и подходящие вам товары и продавцов.",
    help: "Техническая поддержка в телеграм",
    old_version: "Переход на старую версию",
    back_to_main:'Перейти на главную страницу',
    all:'Все',
    confirmed_products:'Готовая продукция',
  },
  footer: {
    subscribe_text: "Будьте в курсе скидок на электронику,<br> товары для детей и для дома",
    subscribe: "Подписаться",
    email_placeholder: "Введите вашу эл.почту",
    follow_us: "Следите за нами в социальных сетях",
    copyright: "© 2021-2023 — OOO «Smart Marketplace». Все права защищены.",
    report_problem: "Проблемы с использованием сайта?",
    stir: "СТИР",
    mfo: "МФО",
    xr: "ҲP",
    branch:"ООО",
    goal_of_payment: "Цель оплаты",
    offert: "В соответствии с публичной офертой залог был перенесен",
    bank_branch: "Шайхантохурский филиал",
    offert_key_title: "На основании условия публичной оферты",
    offert_info_text: '4.5. Деньги закалат переводятся с лицевого счета поставщика на счет ООО "SMART MARKETPLASE" путем перевода (ПEРEЧИСЛEНИE) денег (в большинстве случаев время процессов в системе растягивается по сравнению с обычным)',
  },
  routes: {
    home: "Главная",
    cart: "Корзина",
    login: "Войти в систему",
    register: "Регистрация",
    orders: "Заказы",
    plasticCard: "Карты",
    wishlist: "Избранное",
    checkout: "Оформление заказа",
    products: "Товары",
    personalData: "Персональные данные",
    notifications: "Уведомление",
    contracts: "Контракт",
    invoice: "Счет-фактура",
    compair:'Сравнение',
    cash_money:"Наличные",
    order_confirmation: "Подтвердить заказа",
    
  },
  plastic_card: {
    add_card_title: "Добавить новую карту",
    enter_card_title: "Добавить новую карту",
    choose_card_view_title: "Выберите вид карты",
    choose_card_type_title: "Выберите тип карты",
    enter_card_number_title: "Введите номер карты",
    expiry_date_title: "Дата истечения срока",
    main_card_title: "Основная карта",
    enter_card_name_title: "Введите название карты",
    save_btn_title: "Сохранить",
    confirmSmsTitle: "Подтверждение СМС кода",
    enter_sms_code_title: "Введите код, отправленный на ваш номер телефона",
    not_confirmed_txt: "Не подтвержденный",
    please_confirm_txt: "Пожалуйста, подтвердите вашу карту",
    confirm_txt: "Подтвердить карту",
    delete_modal_title: "Удалить карту",
    confirm_delete_card_title: "Внимания подтверждаете ли вы удаление карты?",
    delete_card_btn: "Удалить",
    exist_card_txt: "Эта карта есть в базе!",
    wrong_code_txt: "Введен неверный код",
    retry_code_txt: "Отправить код повторно",
    not_available: "Не существует"
  },
  login: {
    wrong_credentials: "Проверьте правильность введенных данных",
    fill_all_inputs: "Заполните все поля",
    not_registred: "Вы не зарегистрированы",
    button_text: "Войти",
    esign: "Войти с помощью ЭЦП",
    choose_key: "Выберите ключ из списка",
    date: "Дата",
    auth_required: "Требуется авторизация",
    auth_required_message: "Для продолжения необходимо войти в систему.",
    auth_action_text: "Авторизоваться",
    type:'Выберите удобный для вас способ входа в систему',
    auth_application:'Доступ по номеру заявки',
    checking:"Проверка данных",
    one_id:"Вход через One Id",
    error:"Ошибка входа в систему!",
    login:"Логин",
    password:"Пароль",
    error_login_password:"Ошибка логина или пароля!"
  },
  esign: {
    keys_not_found: "Электронные ключи не найдены",
    client_not_found: "Ошибка при подключении E-IMZO. Установите браузер или модуль E-IMZO.",
    wrong_password: "Проверьте правильность ввода пароля",
    eimzo_site: "Сайт E-IMZO",
    tin: "ИНН",
    pinfl: "ПИНФЛ",
    cert_expired: "Просрочен",
    cert_active: "Активен",
    cert_type_entity: "Юридическое лицо",
    cert_type_individual: "Физическое лицо",
  },
  register: {
    passwords_mismatch: "Введенные пароли не совпадают",
    incorrect_phonenumber: "Введите правильный номер телефона",
  },
  orderStates: {
    CONTRACT_CREATED: "Договор создан",
    CONTRACT_IN_PROCESS: "Договор подписан поставщиком",
    CONTRACT_CANCELED: "Договор расторгнут",
    CONTRACT_REJECTED: "Подпись договора отклонена",
    CONTRACT_FULL_SIGNED: "Договор полностью подписан",
  },
  delivery_types: {
    bring: "Самовывоз со склада продавца",
    delivery: "Доставка курьером",
    choose: "Выберите способ доставки",
  },
  delivery_types_short: {
    bring: "Самовывоз",
    delivery: "Доставка",
  },
  product: {
    card_delivery: "Доставка в ваш регион",
    add_to_cart: "В корзину",
    already_in_cart: "Добавлено",
    added_to_cart: "Товар добавлен в корзину",
    added_to_cart_message:
      "Вы можете перейти в корзину, чтобы оформить заказ прямо сейчас или вернуться позже, завершив покупки.",
    continue_shopping: "Продолжить покупки",
    move_to_cart: "Перейти в корзину",
    delivery_days:
      "Доставка осуществляется продавцом, в течении {days} дней с момента подтверждения заказа",
    delivery_type: "Способ доставки",
    free: "Бесплатно",
    choose_delivery_type: "Выберите способ доставки",
    delivery_select_label: "Как вам удобно получить товар?",
    delivery_address: "Адрес доставки",
    bring_select_label: "Выберите пункт выдачи",
    short_info: "Коротко о товаре",
    unit: "Еденица измерения",
    min_amount: "Минимальное количество",
    expiration_life: "Срок годности",
    free_service_life: "Бесплатный сервис",
    ayear: "Год выпуска",
    country_name: "Страна производитель",
    make_name: "Производитель",
    description: "Описание",
    delivery_unaviable: "Недоступно в вашем регионе",
    bring_addresses: "{count} пункт(ов) выдачи",
    id_number: "ID номер",
    same_products: "Похожие товары",
    detail_info: "Подробная информация",
    comment: "Отзывов",
    leave_feedback: "Оставить комментарий",
    leave_comment_about_product_title: "Оставит отзыв о товаре",
    leave_comment_about_product: "Оставьте отзыв о товаре *",
    you_commented: "Вы прокомментировали этот продукт!",
    you_need_buy_product: "Вы должны купить товар, чтобы оставить комментарий!",
    you_need_login: "Вам нужно войти чтобы оставить комментарий! ",
    good_product: "Хороший продукт",
    bad_product: "Неудовлетворительный продукт",
    comment_txt: "Отзыв",
    good_txt: "Хорошо",
    bad_txt: "Плохо",
    detail_not_found: "Товар не найден",
    asaxiy_products: "Продукты Asaxiy",
    new_product:'Новый',
    base_amount:"Осталось всего {count} {unit}",
    week_sold_amount:'На этой неделе {count} человек купил',
    week_sold_first:"Купить первым на этой неделе",
    market_adresses:"Адреса магазинов",
    market_adresses_title:"Товар доступен в следующих наших магазинах",
    available:"Доступно",
    buy_one:"Покупка в один клик",
    buy_one_buy:"Покупка",
    shipping_type:'Способы получения',
    payment_type:"Способы оплаты",
    copy_data:"Информация скопирована",
    share:"Поделиться"
  },
  seller: {
    reviews: "{count} отзывов",
    show_phonenumber: "Показать номер телефона",
  },
  address: {
    new: "Новый адрес",
    region: "Регион",
    district: "Район",
    address_input_label: "Укажите адрес",
    comment_label: "Комментарий",
  },
  wishlist: {
    empty_text: "Жмите ♡ на странице товара и добавляйте сюда то, что нравится.",
    empty_title:"Список выбранных пуст",
    empty_message:"Включите продукты, которые вам нравятся, в список избранных, чтобы просмотреть их позже или совершить покупку."
  },
  confirmed:{
    empty_title:"Готовых продуктов нет в наличии!",
    empty_text:"Готовых продуктов нет в наличии! Они появятся в этом окне после подтверждения."
  },
  orders: {
    empty_text: "У вас нет заказов.",
    order_proccess: "Процесс заказа № {document_id}",
    performed: "Выполнено",
    not_performed: "Не выполнено",
    in_progress: "В процесса",
    pending: "В ожидании",
    make_order: "Сделать заказ",
    order_confirmation: "Подтвердить заказа",
    order_delivery: "Доставить заказ",
    order_finish: "Завершить заказ",
    unpassed_stage: "Невыполненный этап",
    orders_story: "История заказов",
    product_txt: "Товар",
    amount: "Количество",
    order_rejected: "Отменено",
    order_delivered: "Завершено",
    search_input_placeholder: "Введите название продукта",
    confirm:'Подтверждение',
    send_confirm_code:"Код подтверждения был отправлен на ваш телефон в виде sms!",
    re_send:"Повторная отправка"
  },
  cart: {
    empty_text: "Ваша корзина пуста.",
    total: "Итого",
    products_count: "Товары, {count} шт.",
    delivery: "Доставка",
    remove_confirm_text: "Вы действительно хотите удалить этот товар из корзины?",
    checkout_button_text: "Оформить заказ",
    choose_org:"Выберите компанию для выполнения заказа",
    org:"Организации",
    empty_title:"В корзине сейчас ничего нет",
    empty_message:"Добавьте желаемые продукты в корзину для покупок."
  },
  category: {
    products_count: "{count} товаров",
    empty_text: "Товаров, подходящих критериям запроса, нет.",
  },
  checkout: {
    delivery_unaviable: "Доставка товара не осуществляется в данном направлении",
    payment_type: "Способ оплаты",
    pay_button: "Оплатить заказ",
    choose_payment_type_txt: "Выберите",
    family_credit:"Семейный кредит",
    choose_delivery_type: "Укажите способ доставки",
    choose_delivery_type_message:
      "Не спешите, сначала нужно выбрать удобный для вас способ доставки товаров",
    insufficient_funds: "Недостаточно средств",
    insufficient_funds_message:
      "Сумма оформленного кредита недостаточна для получаемого товара или услуги",
    force_pay: "Оплатить разницу отдельно",
    attention_not_enough_money:"Внимание, на вашем счету недостаточно средств для выполнения заказа!",
    hight_price:"Введенная сумма денег превышает цену заказа!",
    enter_family_credit:"В дополнение к сумме кредита, выделенного из договора заявки Ойлкредит, введены дополнительные виды оплаты",
    additional_payment:"Выберите дополнительный тип оплаты:",
    accept_application:"Ваша заявка принята",
    pay_cash_question:"Вы хотите оплатить этот заказ наличными?",
    accaunt_isnot_full:`Чтобы продолжить оформление заказа, необходимо заполнить <a href="{link}" class="link">личную информацию.</a>` 
  },
  payment_types: {
    credit: "Оформить в кредит Oilakredit",
    cash: "Наличными",
  },
  ocredit: {
    aplications_empty: `У вас нет действующих договоров, чтобы продолжить вы должны <a href="{link}" class="link" target="_blank">оформить договор</a>.`,
    login: "Авторизация",
    login_message: "Укажите данные из кредитной заявки",
    login_info: "Введите номер и дату заявки.",
    auth_error: "Кредитный договор не подписан",
  },
  home: {
    banner0:{
      title1:'Новый год-это новое дело',
      title2:'С <span>Новым</span> годом',
      text:"Хотите иметь собственный семейный бизнес в новом году в котором SMART-MARKET.UZ лучший выбор для этого."
    },
    banner1:{
      title1:'ТЕПЕРЬ ПРОДУКТЫ ASAXIY',
      title2:'НА  <span>SMART-MARKET.UZ</span>',
      text:'Продукты приложения ASAXIY любого типа SMART-MARKET.UZ делайте покупки через.'
    },
    banner2:{
      title1:"ИМЕТЬ СОБСТВЕННЫЙ БИЗНЕС",
      title2:'<span>SMART-MARKET.UZ</span>  С ТОБОЙ',
      text:"Сделайте свой семейный бизнес быстрым и легким или развивайте свой бизнес вместе с нами."
    },
    banner3:{
      title1:"ХОТИТЕ СТАТЬ ФЕРМЕРОМ?",
      title2:'<span>МЫ</span> ПОСТАРАЕМСЯ ДЛЯ ВАС',
      text:"Хотите иметь собственную ферму? У нас это уже не проблема."
    },
    welcome: '<div>Добро пожаловать на</div> <div class="big">Smart Market</div>',
    sponsor: "при поддержке программы",
    ocredit_login_button: "Войти с номером кредитной заявки",
    are_you_seller: "Продаете товары?",
    start_selling: "Начните продавать у нас",
    more: "посмотреть все",
    ocredit_view_products: "Подборка товаров по вашей кредитной заявке",
    telegram_message:
      "Пожалуйста, ознакомьтесь с инструкциями по использованию сайта в нашем телеграм канале.",
    telegram_action: "Перейти в телеграм канал",
    handbook: "Видео инструкции",
    main_slider_title:
      '<h1 class="main_slider_heading">Создайте свой <span>собственный</span> семейный бизнес</h1>',
    main_slider_asaxiy_title:
      '<h1 class="main_slider_heading">Продукты <span>Asaxiy</span> Теперь в <br /> <span>SMART-MARKET.UZ!</span></h1>',
    main_slider_description: "Воплотите свои идеи в жизнь",
    continue_to_buy: "Войдите, чтобы продолжить <br />покупки",
    do_you_buy: "Вы продаете продукты?",
    connect_to_system: "Подключитесь в системе",
    main_categories: "Основные категории",
    average: "Средняя цена товаров и услуг",
    see_all: "Посмотреть все",
    high_products_title: "Товары и услуги, высоко оцененные потребителями",
    best_products_title:"Самые продаваемые товары",
    cheap_products_title: "Самые дешевые товары (по направлениям)",
    new_products_title: "Недавно добавленные продукты",
    bannerTitle: "ПОКУПАЙТЕ ЛЮБИМЫЕ ПРОДУКТЫ В МОБИЛЬНОМ ПРИЛОЖЕНИИ И СЭКОНОМЬТЕ ДО 20%!",
    qrCodeTitle: "Наведите камеру на QR-код, чтобы загрузить",
    detailedTitle: "Подробно",
    simple_with_us:"У нас все намного быстрее и проще!",
    wait_you:"Самые низкие цены ждут вас!"
  },
  banners:{
    with_us:"С нами!"
  },
  sorting_types: {
    popular: "по популярности",
    price: "по цене",
    rating: "по рейтингу",
    review: "по отзывам",
    discount: "по скидке",
    new: "по новизне",
  },
  subscribe: {
    title: "Спасибо за подписку",
    description: "Проверяйте ваш почтовый ящик, чтобы быть в курсе скидок и новостей.",
  },
  issue_report: {
    title: "Сообщить о проблеме",
    description: "Укажите как можно подробнее вашу проблему",
    name: "Как к вам обращаться?",
    phonenumber: "Ваш номер телефона",
    describe_problem: "Опишите вашу проблему",
    send: "Отправить",
    success: "Ваша заявка успешно отправлена",
    wait_until_call:
      "Мы уже обрабатываем ваш запрос, пожалуйста дождитесь звонка нашего оператора.",
  },
  filters: {
    category: "Категория",
    price: "Цена",
    from: "от, сум",
    to: "до, сум",
    available_for_credit: "Доступно в кредит",
    credit_tip: "Можно купить по программе «Oila kredit»",
    delivery_types: "Способы доставки",
    delivery: "Доставка курьером",
    bring: "Самовывоз",
    by_contracts: "Товары по кредитной заявке",
    all_regions: "По всем регионам",
    region: "Регион",
    see_more_txt: "Показать еще",
    see_less_txt: "Показать меньше",
    additional_filters: "Дополнительные фильтры",
    seller_txt: "Поставщики",
    clear_filters: "Сбросить фильтр",
    providers_not_found: "Не удалось найти поставщиков, которые соответствуют вашим критериям поиска",
    sort:"Сортировка",
    filter:'Фильтры',
    show:"Видеть",
    clear:'Сброс'
  },
  personal_data: {
    phone_number: "Номер телефона",
    backup_phone_number: "Дополнительный номер телефона",
    telegramId: "Telegram ID",
    email_box: "Электронная почта",
    save_btn: "Сохранить изменения",
    email_placeholder: "Введите свою почту",
    message_type: "Способ получения уведомления",
    sms: "Смс",
    telegram_bot: "Telegram бот",
    email: "Email",
    get_id_info: "Нажмите на эту кнопку, чтобы получить ID",
    error_photo_title: "Пожалуйста, загрузите изображение",
    error_message_type: "Пожалуйста, выберите способ получения уведомления",
    photo_validation_text: "Пожалуйста, загрузите изображение размером менее 2 Мб",
    id_validation: "Заполните поля номера телефона, чтобы получить ID!",
    id_not_found: "ID не найден!",
    email_validation_text: "Пожалуйста, введите правильный адрес электронной почты!",
    success_send: "Данные успешно сохранены!",
    message_connect_tg_bot: `<span>Вы должны подключиться к боту, чтобы получать уведомления через бота.<a class="pui-text is-primary" href="https://t.me/smart_market_rs_bot" target="_blank"> Перейти к боту</a></span>`,
    input_tg_bot: `<span>Вы должны подключиться к боту, чтобы получить ID.<a class="pui-text is-primary" href="https://t.me/smart_market_rs_bot" target="_blank"> Перейти к боту</a></span>`,
    add_new_adress: "+ Добавить новый адрес",
    delivery_addresses: "Введите регионы доставки",
  },
  documents: {
    empty_contracts: "Нет доступных контрактов",
    provider: "Поставщик",
    contract_txt: "Договр",
    signed_day: "Дата подписания",
    state: "Статус",
    count_txt: "Номер",
    date_txt: "Дата",
    sum_txt: "Сумма",
    seller_txt: "Продавец",
    buyer_txt: "Покупатель",
    rejected: "Отказано",
    view: "Рассматривается",
    created: "Принято",
    in_process: "На подписании",
    in_process_confirmation:"Подтверждение",
    canceled: "Отменено",
    rejected: "Отказано",
    full_signed: "Полностью подписан",
    sys_canceled: "Отказ от системы",
    cancel_view: "Отмена",
    contract_is_canceled:"Ваш контракт расторгнут!",
    contract_is_canceled_message:"Ваш контракт расторгнут, в любое время мы готовы заключить с вами новый контракт.",
    cancel_contract:"Расторжение договора",
    cancel_contract_right:"Почему вы хотите расторгнуть этот контракт?",
    cancel_contract_reason:"Причина расторжения договора",
    search_by_number_placeholder: "Номер контракта",
    seacrch_by_number_name:"Название продукта или номер контракта",
    canceled_date:'Дата отмены',
    payment:"Оплата",
    cash_money:"Наличные",
    total_cash:"Общая сумма",
    confirm_orers:"Ваш заказ успешно оформлен!",
    confirm_orers_text:"Ваш заказ оформлен вы можете увидеть чек заказа в разделе наличные!",
    order_is_canceled:"Ваш заказ отменен!",
    order_is_canceled_message:"Ваш заказ отменен, вы можете сделать повторный заказ в любое время.",
    cancel_order:"Отмена заказа", 
    cancel_order_right:"Почему вы хотите отменить этот заказ?",
    cancel_order_reason:"Причина отмены заказа",
  },
  invoices: {
    empty_invoices: "Счета-фактуры недоступны",
    corporation_txt: "Предприятие",
    customer: "Клиент",
    helper_hokim: "Акт помощника хокима",
    result: "Результат",
    search_by_number_placeholder: "Введите номер счета-фактуры",
  },
  notifications: {
    empty_notification: "Нет новых уведомлений",
    empty_reads_notification: "Прочитанные уведомления недоступны",
    newest: "Новые",
    olders: "Предыдущие",
  },
  tariffs:{
    name:"Тарифы",
    price:'Стоимость услуги:',
    info:'О сервисе:',
    SILVER:{
      price:'Составляет 1 процент от суммы оферты поставки между Поставщиком и Покупателем, но минимальная стоимость услуг не должен быть меньше 1 процента от БРВ.',
      info:'В данном тарифе Поставщику предоставляются следующие дополнительные услуги: прием заявок, их модерация,  включение в базу данных товаров и/или услуг), реализация товаров и/или услуг (подготовка  проектов  договоров  поставок,  счет-фактур и соответствующих сообщений  программным  путем), осуществления расчетов за поставки (наличными/безналичными, за счет кредитов банков), отражения результатов реализаций на складском учете,  а также изучения спроса (30 заявок в месяц),  размещения в ТОП список Поставщиков (на 5 дней в месяц) посредством информационной системы smart-market.uz.'
    },
    GOLD:{
      price:'Составляет 2 процент от суммы оферты поставки между Поставщиком и Покупателем, но минимальная стоимость услуг не должен быть меньше 1 процента от БРВ.',
      info:'В данном тарифе Поставщику предоставляются следующие дополнительные услуги: прием заявок, их модерация,  включение в базу данных товаров и/или услуг), реализация товаров и/или услуг (подготовка  проектов  договоров  поставок,  счет-фактур и соответствующих сообщений  программным  путем), осуществления расчетов за поставки (наличными/безналичными, за счет кредитов банков), отражения результатов реализаций на складском учете,  а так же изучения спроса (60 заявок в месяц),  отправке предложений  Покупателям в первую очередь (60 предложений в месяц), прохождения модерации вне очереди (10 раз в месяц), размещения в ТОП список Поставщиков (на 10 дней в месяц) посредством  информационной системы smart-market.uz'
    },
    PLATINUM:{
      price:'Составляет 3 процент от суммы оферты поставки между Поставщиком и Покупателем, но минимальная стоимость услуг не должен быть меньше 1 процента от БРВ.',
      info:'В данном тарифе Поставщику предоставляются следующие дополнительные услуги: прием заявок, их модерация,  включение в базу данных товаров и/или услуг), реализация товаров и/или услуг (подготовка  проектов  договоров  поставок,  счет-фактур и соответствующих сообщений  программным  путем), осуществления расчетов за поставки (наличными/безналичными, за счет кредитов банков), отражения результатов реализаций на складском учете,  а так же изучения спроса (без ограничений),   отправке предложений  Покупателям в первую очередь (без ограничений), прохождения модерации вне очереди (без ограничений), обращения в службу технической поддержки вне очереди, интегрирацию бугалтерию с системой  (за отдельную плату), размещения рекламы товаров и /или услуг (на веб-странице Исполнителя 2 раза, на Telegram канале Исполнителя 1 раз в месяц),  размещению в ТОП список Поставщиков (постоянно) посредством  информационной системы smart-market.uz'
    }
  }
};
