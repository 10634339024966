import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '../../store';
import ru from './locales/ru';
import uz from './locales/uz';
import la from './locales/la';
import qq from './locales/qq';
import en from './locales/en'

Vue.use(VueI18n);

const messages = { ru, uz, la, qq, en };
const lang= process.env.VUE_APP_LANG ?  process.env.VUE_APP_LANG :'en';
const i18n = new VueI18n({
  locale: lang,
  messages,
});

Vue.prototype.$locale = {
  change(lang) {
    i18n.locale = lang;
    store.commit('Account/CHANGE_LANG', lang);
  },
  current() {
    return i18n.locale;
  },
};

export default i18n;
