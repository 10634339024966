import Vue from "vue"

const initialState = {
  footer: [],
  page: {},
  homepageAds: []
}

export default {
  namespaced: true,
  state: { ...initialState },
  mutations: {
    SET(state, [key, value]) {
      state[key] = value
    },
    CLEAR(state, key) {
      state[key] = initialState[key]
    }
  },
  actions: {
    getFooter({ commit, rootState }) {
      commit('CLEAR', 'footer')
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.get('api/v1/rubrics', {
          params: {
            lang: rootState['Account'].account.user.locale,
            slug: 'footer',
            name:'muzaffarapi'
          }
        }).then((response) => {
          commit('SET', ['footer', response.data.data])
          resolve(response.data.data)
        }).catch((error) => {
          reject(error.response)
        })
        .finally(()=>{
          commit('SET_LOADING', false, {root: true})
        })
      })
    },
    getHomepageAds({ commit, rootState }) {
      commit('CLEAR', 'homepageAds')
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.get('api/rubrics', {
          params: {
            lang: rootState['Account'].account.user.locale,
            slug: 'homepage_ads'
          }
        }).then((response) => {
          commit('SET', ['homepageAds', response.data.data[0].pages])
          resolve(response.data.data[0].pages)
        }).catch((error) => {
          reject(error.response)
        })
        .finally(()=>{
          commit('SET_LOADING', false, {root: true})
        })
      })
    },
    getPage({ commit, rootState }, slug) {
      commit('CLEAR', 'page')
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.get('api/page', {
          params: {
            lang: rootState['Account'].account.user.locale,
            slug
          }
        }).then((response) => {
          commit('SET', ['page', response.data.data])
          resolve(response.data.data)
        }).catch((error) => {
          reject(error.response)
        })  
        .finally(()=>{
          commit('SET_LOADING', false, {root: true})
        })
      })
    }
  },
}
