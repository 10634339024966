import Vue from "vue";

export default {
  namespaced: true,
  state: {
    profileImgId: null,
    notificationsData: {},
  },
  mutations: {
    SET_IMAGE_ID(state, id) {
      state.profileImgId = id;
    },
    SET_NOTIFICATIONS(state, data) {
      state.notificationsData = data;
    },
  },
  getters: {
    notificationCount: (state) => state.notificationsData.unreads?.messages.length,
  },
  actions: {
    getPersonalData({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.get("api/v1/user")
          .then((response) => {
            commit("SET_IMAGE_ID", response.data.data.photo);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error.response.data);
          })
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
      });
    },

    getNotificationData({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get("api/v1/user/notifications")
          .then((response) => {
            commit("SET_NOTIFICATIONS", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
  },
};
