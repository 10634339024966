import { createAxiosInstance } from "./http"

const storage = window.localStorage

export default {
  axiosInstance: null,
  tokenKey: '',
  install(Vue, options) {
    // baseURL: process.env.VUE_APP_API_URL
    const { projectKey, store, router, baseURL } = options

    this.axiosInstance = createAxiosInstance({
      store,
      baseURL,
      httpErrorHandler: (status) => { 
        switch (status) {
          case 401:
            this.removeAuthToken()
            store.commit('Account/LOGOUT') 
            if(router.currentRoute.name!='login'){
              router.push({name: 'login',query: { redirect: window.location.pathname }})
            }
           
            break;
          // case 404:
          //   router.push({ name: '404' });
          //   break;
        }
      }
    })

    this.tokenKey = `${projectKey}__token`;
    const token = storage.getItem(this.tokenKey);

    if (token) {
      this.setAuthToken(token)
    }

    Vue.prototype.$axios = this.axiosInstance
    Vue.axios = this.axiosInstance
    Vue.prototype.$http = this
    Vue.http = this
  },
  setAuthToken(token) {
    this.axiosInstance.defaults.headers.authorization = `Bearer ${token}`;
    storage.setItem(this.tokenKey, token);
  },
  removeAuthToken() {
    delete this.axiosInstance.defaults.headers.authorization;
    storage.removeItem(this.tokenKey);
  }
}