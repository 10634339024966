import Vue from "vue"

export default {
  namespaced: true,
  state: {
    products: []
  },
  getters: {
    hasProduct: (state) => (id) => !!state.products.find(product => product.id === id || product.product_id === id ),
    productsCount: (state) => state.products.length
  },
  mutations: {
    ADD(state, product) {
      state.products = [...state.products, product]
    },
    REMOVE(state, id) {
      state.products = state.products.filter(product => product.id !== id)
    },
    REMOVE_FROM_CART(state, id) { 
      state.products = state.products.filter(product => product.product_id !== id)
    },
    CLEAR(state) {
      state.products = []
    },
    SET_WISHLIST(state, wishlist) {
      state.products = wishlist;
    },
  },
  actions: {
    SetProduct({  rootGetters, dispatch, getters, commit }, data){  
      console.log("AAA")
      if(rootGetters['Account/isAuthenticated']){ 
        return new Promise((resolve, reject) => {  
          if (!getters.hasProduct(data.product_id ? data.product_id :data.id)){ 
            
            let productData={ 
              products:[
              {
                num:getters.productsCount+1,
                type:'selected',
                product_id:data.product_id?data.product_id:data.id
              }
            ]}
            Vue.axios.post("api/v1/buyer/products?type=selected", productData)
              .then((res) => { 
                dispatch('GetProduct')
                .finally(()=>{
                  resolve(res.data); 
                })
                
              })
              .catch((error) => {
                reject(error.response);
              });
          }
          else{
            let productData={ 
                type:'selected',
                product_id:data.product_id ? data.product_id :data.id
              }
            Vue.axios.put("api/v1/buyer/products", productData)
            .then((res) => {  
              dispatch('GetProduct')
              .finally(()=>{
                resolve(res.data); 
              }) 
            })
            .catch((error) => { 
              reject(error.response);
            });  
          }
        });
      }
      else{
        if(data.product_id){
          if (getters.hasProduct(data.product_id)) {
            commit('REMOVE_FROM_CART', data.product_id)
          } else {
            commit('ADD', data)
          }
        }
        else{
          if (getters.hasProduct(data.id)) {
            commit('REMOVE', data.id)
          } else {
            commit('ADD', data)
          }
        }
        
      }
     
    }, 
    GetProduct({commit, rootGetters, rootState}){
      if(rootGetters['Account/isAuthenticated']){
      return new Promise((resolve, reject) => {
        Vue.axios.get("api/v1/buyer/products?type=selected",{
          params:{
            buyer_tin: rootState["Account"].account.user.orgId,
          }
        })
          .then((response) => {
            commit("SET_WISHLIST", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    }
    else{

    }
    }, 
    CheckWishlist({state, dispatch}){ 
      if(state.products.length){
        let data={
          products:[]
        }
        state.products.forEach((element, index)=>{
          data.products.push({
            num:index,
            type:'selected',
            product_id:element.product_id?element.product_id:element.id
          })
        })
        Vue.axios.post("api/v1/buyer/products?type=selected", data).then(() => { dispatch('GetProduct') }) 
      }
      else{
        dispatch('GetProduct')
      } 
    },
  },
}