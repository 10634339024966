import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import httpAuth from './core/http-auth'
import mobile from './core/mobile'
import i18n from './core/i18n'
import PlatonUI from 'platon-ui'
import VueToast from 'vue-toast-notification';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { ObserveVisibility } from 'vue-observe-visibility'
import ZoomOnHover from "vue-zoom-on-hover";

import 'platon-ui/scss/platon-ui.scss'
import '@/assets/scss/main.scss'
import '@/assets/scss/mobile.scss'
import 'nprogress/nprogress.css';
import 'vue-toast-notification/dist/theme-sugar.css';

import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

Vue.config.productionTip = false

Vue.use(PlatonUI)
Vue.use(httpAuth, {
  store,
  router,
  projectKey: 'SMART_MARKETPLACE',
  baseURL: process.env.VUE_APP_API_URL
})
Vue.use(mobile)
Vue.use(VueToast);
Vue.use(ZoomOnHover);
Vue.directive('observe-visibility', ObserveVisibility)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ru-RU';
Vue.use(ElementUI, { locale });



import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)


if(process.env.NODE_ENV == 'production') {
  Sentry.init({
    Vue,
    dsn: "https://047b52c3f5e14e3e9fbec6eadc2b9e87@o1161019.ingest.sentry.io/6245977",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "smart-market.uz", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
  });

  Sentry.configureScope(scope => {
    scope.setExtra(store.state)
  })
}






new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
