import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from './persistedState'

import Account from './modules/account'
import Regions from './modules/regions'
import Categories from './modules/categories'
import Products from './modules/products'
import Cart from './modules/cart'
import Credit from './modules/credit'
import PlasticCard from './modules/plasticCard'
import Orders from './modules/orders'
import Wishlist from './modules/wishlist'
import Addresses from './modules/addresses'
import Rubrics from './modules/rubrics'
import Profile from './modules/profile'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Account,
    Regions,
    Categories,
    Products,
    Cart,
    Credit,
    PlasticCard,
    Orders,
    Wishlist,
    Addresses,
    Rubrics,
    Profile
    
  },
  state: {
    loading: false
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading
    },
  },
  plugins: [persistedState]
})
