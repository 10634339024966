import Vue from "vue"

export default {
  namespaced: true,
  state: {
    applications: []
  },
  mutations: {
    SET_APPLICATIONS(state, applications) {
      state.applications = applications
    },
    CLEAR_APPLICATIONS(state) {
      state.applications = []
    }
  },

  getters: {
    availableSum(state) {
      return state.applications[0]?.available_sum 
    }
  },

  actions: {
    getApplications({ commit }) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_APPLICATIONS')
        resolve([])
        // commit('SET_LOADING', true, {root: true})
        // Vue.axios.get('api/v1/sync/familycredit/applications').then((response) => {
        //   if(response.data.data && response.data.data.length){
        //     commit('SET_APPLICATIONS', response.data.data)
        //   }
        //   else{
        //     commit('SET_APPLICATIONS', [])
        //   }
       
        //   resolve(response.data)
        // }).catch((error) => {
        //   reject(error.response)
        // })
        // .finally(()=>{
        //   commit('SET_LOADING', false, {root: true})
        // })
      })
    }
  },
}