export default {
    lang: 'English',
    interface: {
        unknown_erorr: 'Unknown error: {error}',
        error_occurred: 'An error occurred',
        error_message: 'The operation encountered an error. Please seek technical assistance to resolve this issue.',
        fill_required_fields: '(*) Fill out the required fields',
        more: 'Details',
        hide: 'Hide',
        edit: 'Edit',
        choose: 'Choose',
        back: 'Back',
        pagination_back:'Prev',
        forward: 'Next',
        wishlist: 'Wishlist',
        remove: 'Remove',
        cancel: 'Cancel',
        save: 'Save',
        are_you_sure: 'Are you sure?',
        understand: 'Understand',
        search: 'Search',
        all_search_results: 'All search results',
        avarage_costs: 'Average prices in the Smart-market.uz marketplace',
        seen_results: 'You have viewed {viewedItems} results out of {total}',
        search_undefined:"No information found matching search criteria!",
        yes:"Yes",
        no:"No"
    },
    navigation: {
        seller_cabinet: 'Personal cabinet',
        orders: 'Orders',
        my_orders: 'My orders',
        my_cards: 'My cards',
        notifications: 'Notifications',
        wishlist: 'Wishlist',
        cart: 'Cart',
        logout: 'Logout',
        login: 'Login',
        main: "Main",
        category: "Category",
        profile: "Profile",
        documents: "Documents",
        contracts: "Contracts",
        invoices: "Invoices",
        all_categories: 'All categories',
        search_placeholder: 'Enter product name or id',
        search_button: 'Search',
        delivery_to: 'Your address <span>{place}</span>',
        choose_delivery: 'Select district/city',
        choose_delivery_message: 'Select the city/town you are in so that we can display the nearest and most suitable products and vendors for you',
        help: 'Technical support via Telegram',
        old_version: "Switch to old version",
        back_to_main:'Go to main page',
        all:'All',
        confirmed_products:'Confirmed products',
    },
    footer: {
        subscribe_text: 'Get informed about discounts on electronics, products for children and home goods',
        subscribe: 'Subscribe',
        email_placeholder: 'Enter your email',
        follow_us: 'Follow us on social networks',
        copyright: '© 2021-2023 — «Smart Marketplace» LLC. All rights reserved.',
        report_problem: 'Are there any issues you are facing while using website?',
        stir: "STIR",
        mfo: "MFO",
        xr: "xR",
        branch:"LLC",
        goal_of_payment: "Transaction purpose",
        offert: "In accordance with terms of the public offer, the pledge was transferred",
        bank_branch: "Shayxontohur branch",
        offert_key_title: "In accordance with the terms of the public offer",
        offert_info_text: '4.5. The pledge is transferred from the supplier\'s account to the account of LLC \'SMART MARKETPLACE\' through funds transfer (REMITTANCE) process. (in most cases, the system encounters delays in processing compared to the standard procedures)',
    },
    routes: {
        home: 'Home page',
        cart: 'Cart',
        login: 'Login',
        register: 'Register',
        orders: 'Orders',
        plasticCard: 'Cards',
        wishlist: 'Wishlist',
        checkout: 'Proceed to checkout',
        products: 'Products',
        personalData: 'Personal data',
        notifications: 'Notifications',
        contracts: 'Contracts',
        invoice: 'Invoice',
        compair:'Compare',
        cash_money:"Cash",
        order_confirmation: "Order confirmation",
    },
    plastic_card: {
        add_card_title: "Add new card",
        enter_card_title: "Enter new card",
        choose_card_view_title: "Select card view",
        choose_card_type_title: "Select card type",
        enter_card_number_title: "Enter card number",
        expiry_date_title: "Expiry date",
        main_card_title: "Main card",
        enter_card_name_title: "Enter card name",
        save_btn_title: "Save",
        confirmSmsTitle: "Sms confirmation code",
        enter_sms_code_title: "Enter code that was sent to your phone",
        not_confirmed_txt: "Not confirmed",
        please_confirm_txt: "Please confirm your card",
        confirm_txt: "Confirm card",
        delete_modal_title: "Delete card",
        confirm_delete_card_title: "Do you want to delete the card?",
        delete_card_btn: "Delete",
        exist_card_txt: "This card already registered in database!",
        wrong_code_txt: "Entered code is incorrect!",
        retry_code_txt: "Resend the code",
        not_available: "Does not exist",
    },
    login: {
                wrong_credentials: 'Check accuracy of entered data',
                fill_all_inputs: 'Fill out all the fields',
                not_registred: 'You are not registered',
                button_text: 'Login',
                esign: 'Login with E-signature',
                choose_key: 'Choose key from the list',
                date: 'Date',
                auth_required: 'Authorization required',
                auth_required_message: 'You need to login to continue',
                auth_action_text: 'Authorize',
                type:'Please choose the login method that is convenient for you',
                auth_application:'Sign in with request number',
                checking:"Checking...",
                one_id:"Login with OneID",
                error:"Login failed",
                login:"Login",
                password:"Password",
                error_login_password:"Invalid login or password!"
            },
            esign: {
                keys_not_found: 'Electron keys not found',
                client_not_found: 'An error occurred while accessing the E-IMZO. Install the browser or E-IMZO module.',
                wrong_password: '"Double-check the correctness of the entered password.',
                eimzo_site: 'E-IMZO website',
                tin: 'TIN',
                pinfl: 'PINFL',
                cert_expired: 'Expired',
                cert_active: 'Active',
                cert_type_entity: 'Legal entity',
                cert_type_individual: 'Physical entity',
            },
            register: {
                passwords_mismatch: 'The passwords do not match',
                incorrect_phonenumber: 'Enter a valid phone number',
            },
            orderStates: {
                CONTRACT_CREATED: 'Contract created',
                CONTRACT_IN_PROCESS: 'Supplier signed the contract',
                CONTRACT_CANCELED: 'Contract canceled',
                CONTRACT_REJECTED: 'Contract rejected',
                CONTRACT_FULL_SIGNED: 'Contract completely signed',
            },
            delivery_types: {
                bring: 'Self-pickup from the seller\'s warehouse',
                delivery: 'Courier delivery',
                choose: 'Choose delivery type',
    },
    delivery_types_short: {
        bring: 'Self-pickup',
        delivery: 'Delivery',
    },
    product: {
        card_delivery: 'Delivery',
        add_to_cart: 'Add to cart',
        already_in_cart: 'Added',
        added_to_cart: 'Product added to cart',
        added_to_cart_message: 'You can proceed to the shopping cart to place your order now, or return later to complete your purchases',
        continue_shopping: 'Continue shopping',
        move_to_cart: 'Go to cart',
        delivery_days: 'Once the order is confirmed, the seller will proceed with the delivery within {days} days',
        delivery_type: 'Delivery type',
        free: 'Free',
        choose_delivery_type: 'Choose delivery type',
        delivery_select_label: 'How would you prefer to receive goods?',
        delivery_address: 'Delivery address',
        bring_select_label: 'Select pickup location',
        short_info: 'Product overview',
        unit: 'Measurement unit',
        min_amount: 'Minimum quantity',
        expiration_life: 'Expiration date',
        free_service_life: 'Free service',
        ayear: 'Manufacturing year',
        country_name: 'Country of origin',
        make_name: 'Manufacturer',
        description: 'Description',
        delivery_unaviable: 'Not available in your region',
        bring_addresses: '{count} pickup location(s) available.',
        id_number: 'ID number',
        same_products: 'Related products',
        detail_info: 'Detailed information',
        comment: 'Reviews',
        leave_feedback: 'Leave comment',
        leave_comment_about_product_title: 'Leave comment about product',
        leave_comment_about_product: 'Leave comment about product *',
        you_commented: 'You have already commented on this product!',
        you_need_buy_product: 'To leave a comment, you must first purchase the product!',
        you_need_login: 'To leave a comment, you must log in to the system! ',
        good_product: "Good product",
        bad_product: "Unsatisfactory product",
        comment_txt: "Comment",
        good_txt: "Good",
        bad_txt: "Bad",
        detail_not_found: "Product not found",
        asaxiy_products: 'Asaxiy products',
        new_product:'New',
        base_amount:"Only {count} {unit} left in stock",
        week_sold_amount:'This week, {count} people have already bought it',
        week_sold_first:"Be the first to purchase it this week",
        market_adresses:"Store locations",
        market_adresses_title:"The product is available in our following stores",
        available:"Available",
        buy_one:"One-click purchase",
        buy_one_buy:"Purchase",
        shipping_type:'Receiving methods',
        payment_type:"Payment methods",
        copy_data:"Data copied",
        share:"Share"
    },
    seller: {
        reviews: '{count} comments',
        show_phonenumber: 'View phone number'
    },
    address: {
        new: 'New address',
        region: 'Region',
        district: 'District',
        address_input_label: 'Enter address',
        comment_label: 'Comment'
    },
    wishlist: {
        empty_text: 'Click the ♡ button on the product page to add your favorite product to this place.',
        empty_title:"Wishlist is empty",
        empty_message:"To view or purchase products that have caught your interest, please add to wishlist."
    },
    confirmed:{
      empty_title:"There are no confirmed products!",
      empty_text:"There are no confirmed products! Upon confirmation, they will be displayed in this window."
    },
    orders: {
        empty_text: 'There are no  orders in your account.',
        order_proccess: "Order process № {document_id}",
        performed: "Completed",
        not_performed: "Not completed",
        in_progress: "In process",
        pending: "Pending",
        make_order: "Order",
        order_confirmation: "Confirm order",
        order_delivery: "Deliver order",
        order_finish: "Complete order",
        unpassed_stage: "Uncompleted stage",
        orders_story: "Order history",
        product_txt: "Product",
        amount: "Quantity",
        order_rejected: "Rejected",
        order_delivered: "Completed",
        search_input_placeholder: "Enter product name",
        confirm:'Confirm',
        send_confirm_code:"The verification code has been sent to your phone via SMS.!",
        re_send:"Resend",
    },
    cart: {
        empty_text: 'Your cart is empty.',
        total: 'Total',
        products_count: 'Product, {count} unit(s)',
        delivery: 'Delivery',
        remove_confirm_text: 'Do you want to remove product from cart?',
        checkout_button_text: 'Proceed to checkout',
        choose_org:"Select organization for order processing",
        org:"Organizations",
        empty_title:"There is nothing in cart",
        empty_message:"Add desired products to the shopping cart."
    },
        category: {
            products_count: '{count} product(s)',
            empty_text: 'There are no products that match the search criteria.'
        },
        checkout: {
            delivery_unaviable: 'Delivery of goods is not available in this direction',
            payment_type: 'Payment method',
            pay_button: 'Pay',
            choose_payment_type_txt: "Choose",
            family_credit:"Family credit",
            choose_delivery_type: 'Choose delivery type',
            choose_delivery_type_message: 'Please, select method of delivery',
            insufficient_funds: 'Insufficient funds',
            insufficient_funds_message: 'Insufficient credit amount for the desired product or service.',
            force_pay: 'Pay the price difference using personal funds',
            attention_not_enough_money:"Attention! There are not enough funds available in your account to proceed with the order.",
            hight_price:"The entered amount exceeds the price of the order!",
            enter_family_credit:"Additional payment options have been entered beyond the amount of credit allocated from the Oilacredit application agreement",
            additional_payment:"Choose additional type of payment:",
            accept_application:"Your application has been accepted!",
            pay_cash_question:"Are you planning to make the payment for this order in cash?",
            accaunt_isnot_full:`To proceed with order, please provide your <a href="{link}" class="link">personal information.</a>`
        },
        payment_types: {
            credit: 'Apply for OilaCredit installment plan',
            cash: 'Pay in cash',
        },
        ocredit: {
            aplications_empty: `You don't have any active contracts. To proceed, you need to <a href="{link}" class="link" target="_blank">create a contract</a>.`,
            login: 'Login',
            login_message: 'Provide the data from your credit application',
            login_info: 'Please enter the application number and date..',
            auth_error: 'Loan agreement not signed',
        },
        home: {
            banner0:{
                title1:'New Year - new beginnings',
                title2:'<span>Happy</span> new year',
                text:"Do you want to start your own family business "
            },
            banner1:{
                title1:'PRODUCTS FROM ASAXIY',
                title2:'AT <span>SMART-MARKET.UZ</span> ',
                text:'Purchase ASAXIY application products of any type through SMART-MARKET.UZ',
            },
            banner2:{
                title1:"BECOME BUSINESS OWNER",
                title2:'<span>SMART-MARKET.UZ</span> WITH YOU',
                text:"Make your family business fast and easy or grow your business with us."
            },
            banner3:{
                title1:"DO YOU WANT TO BE A FARMER?",
                title2:'<span>WE</span> WORK HARD FOR YOU',
                text:"Dreaming of owning your own farm? We can make it a reality."
            },
        welcome: '<div>Welcome to</div> <div class="big">Smart Market</div>',
        sponsor: 'Powered by our software',
        ocredit_login_button: 'Login to system using assigned number of your loan request',
        are_you_seller: 'Do you sell products?',
        start_selling: 'Start selling with us',
        more: 'View all',
        ocredit_view_products: 'Collection of products in accordance with your credit application',
        telegram_message: 'Find step-by-step instructions for using our web application in our Telegram channel.',
        telegram_action: 'Join now to proceed',
        handbook: 'Instructions',
        main_slider_title: '<h1 class="main_slider_heading">Create </br> Your Family <span>business</span></h1>',
        main_slider_asaxiy_title:
            '<h1 class="main_slider_heading">PRODUCTS FROM <span>Asaxiy</span> <br /> <span>AT SMART-MARKET.UZ!</span></h1>',
        main_slider_description: "Put your ambitions into real life",
        continue_to_buy: "Login to proceed your shopping",
        do_you_buy: "Do you sell products?",
        connect_to_system: "Connect to system",
        main_categories: "Main categories",
        average: "Average price of products and services",
        see_all: "View all",
        high_products_title: "Goods and service highly valued by customers",
        best_products_title:"Best-selling products",
        cheap_products_title: "Cheapest products (by categories)",
        new_products_title: "Recently added products",
        qrCodeTitle: "Scan QR code with your camera to start downloading.",
        bannerTitle: "TAKE ADVANTAGE OF UP TO 20% DISCOUNT BY PURCHASING YOUR BELOVED PRODUCTS THROUGH MOBILE APPLICATION!",
        detailedTitle: "Detailed",
        simple_with_us:"EVERYTHING WITH US IS MUCH FASTER AND MORE CONVENIENT",
        wait_you:"THE LOWEST PRICES AWAIT YOU!"
    },
    banners:{
        with_us:"With us!"
    },
    sorting_types: {
            popular: 'by popularity',
            price: 'by price',
            rating: 'by ranking',
            review: 'by reviews',
            discount: 'by discount',
            new: 'by novelty',
    },
    subscribe: {
        title: 'Thank you for subscribing',
        description: 'Check your email for the latest discounts and news.'
    },
    issue_report: {
        title: 'Report a problem',
        description: 'Please specify your problem in as much detail as possible',
        name: 'First name',
        phonenumber: 'Phone number',
        describe_problem: 'Describe your problem',
        send: 'Submit',
        success: 'Your request has been successfully submitted!',
        wait_until_call: 'We are already processing your request, please wait for a call from our operator.'
    },
    filters: {
        category: 'Category',
        price: 'Price',
        from: 'from, sum',
        to: 'to, sum',
        available_for_credit: 'Available on credit',
        credit_tip: 'Can be purchased through the «Oila kredit» program',
        delivery_types: 'Delivery type',
        delivery: 'Courier delivery',
        bring: 'Pickup',
        by_contracts: 'Products available through a credit application',
        all_regions: 'By all regions',
        region: 'Region',
        see_more_txt: "Show more",
        see_less_txt: "Show less",
        additional_filters: "Additional filters",
        seller_txt: "Suppliers",
        clear_filters: "Clear all",
        providers_not_found: "No suppliers found that match your search criteria",
        sort:"Sort",
        filter:'Filters',
        show:'Show',
        clear:'Clear'
    },
    personal_data: {
        phone_number: "Phone number",
        backup_phone_number: "Additional phone number",
        telegramId: "Telegram ID",
        email_box: "Email address",
        save_btn: "Save changes",
        email_placeholder: "Enter email address",
        message_type: "Choose message type",
        sms: "Sms",
        telegram_bot: "Telegram bot",
        email: "Email",
        get_id_info: "Click here to get ID",
        error_photo_title: "Please, upload a photo",
        error_message_type: "Please, choose message type",
        photo_validation_text: "Please upload a photo less than 2MB",
        id_validation: "Please, fill phone number field to get ID",
        id_not_found: "ID not found!",
        email_validation_text: "Please enter correct email address!",
        success_send: "The information has been successfully saved!",
        message_connect_tg_bot: `<span>You must be connected to the bot to receive notifications through the bot.<a class="pui-text is-primary" href="https://t.me/smart_market_rs_bot" target="_blank"> Go to bot</a></span>`,
        input_tg_bot: `<span>You must be connected to the bot to get an ID.<a class="pui-text is-primary" href="https://t.me/smart_market_rs_bot" target="_blank"> Go to bot</a></span>`,
        add_new_adress: "+ Add new address",
        delivery_addresses: "Please enter delivery regions",
    },
    documents: {
        empty_contracts: "No contracts available",
        provider: "Supplier",
        contract_txt: "Contract",
        signed_day: "Signature data",
        state: "Status",
        count_txt: "Number",
        date_txt: "Data",
        sum_txt: "Amount",
        seller_txt: "Vendor",
        buyer_txt: "Buyer",
        rejected: "Rejected",
        view: "Reviewing",
        created: "Accepted",
        in_process: "At the signing stage",
        in_process_confirmation:"At the verification stage",
        canceled: "Canceled",
        full_signed: "Fully signed",
        sys_canceled: "System rejection",
        cancel_view: "Cancel",
        contract_is_canceled:"Your contract has been terminated!",
        contract_is_canceled_message:"Your contract has been terminated, we are ready to commence a new contract with you whenever you wish .",
        cancel_contract:"Terminate contract",
        cancel_contract_right:"Why do you want to terminate the contract?",
        cancel_contract_reason:"Reason for termination the contract",
        search_by_number_placeholder: "Contract number",
        seacrch_by_number_name:"Product name or contract number",
        canceled_date:'Date of termination',
        payment:"Payment",
        cash_money:"Cash",
        total_cash:"Total price",
        confirm_orers:"Your order has been successfully confirmed!",
        confirm_orers_text:"Your order has been processed you can see the order receipt in the cash section!",
        order_is_canceled:"Your order has been canceled!",
        order_is_canceled_message:"Your order has been canceled, you can reorder whenever you wish.",
        cancel_order:"Cancel order",
        cancel_order_right:"Why do you want to cancel this order?",
        cancel_order_reason:"Reason for canceling the order",
    },
    invoices: {
        empty_invoices: "No invoices found.",
        corporation_txt: "Enterprise",
        customer: "Customer",
        helper_hokim: "Deputy mayor's report",
        result: "Result",
        search_by_number_placeholder: "Enter invoice number",
    },
    notifications: {
        empty_notification: "There are no new notifications.",
        empty_reads_notification: "Unable to view read notifications.",
        newest: "New",
        olders: "Old",
    },
    tariffs:{
        name:"Tariffs",
        price:'Service fee:',
        info:'Service overview:',
        SILVER:{
            price:'The offer amount between the Buyer and the Supplier constitutes 1 percent of the total offer amount, but this amount cannot be less than 1 percent of the Minimum Salary(MS).',
                info:'In this tariff, the supplier is offered the following additional services: receiving orders, their moderation, inclusion in the database of goods and / or services, implementation of goods and / or services (preparation of draft supply contracts, invoices and corresponding messages by software), making payments for supplies (cash / non-cash, at the expense of bank loans), representing the results of sales in warehouse accounting, as well as studying demand (30 applications per month), placing in the TOP list of Suppliers (for 5 days per month) through the smart-market.uz information system.'
        },
        GOLD:{
            price:'The offer amount between Buyer and Supplier constitutes 2% of the total amount, but this amount cannot be less than 1% of the Minimum Salary(MS).',
            info:'In this tariff, the supplier is offered the following additional services: receiving orders, their moderation, inclusion in the database of goods and / or services, implementation of goods and / or services (preparation of draft supply contracts, invoices and corresponding messages by software), making payments for supplies (cash / non-cash, at the expense of bank loans), representing the results of sales in warehouse accounting, as well as studying demand (60 applications per month), sending offers to Buyers first (60 offers per month), passing moderation out of turn (10 times per month), placing in the TOP list of Suppliers (for 10 days per month) through the smart-market.uz information system.'
        },
        PLATINUM:{
            price:'The offer amount between Buyer and Supplier constitutes 3% of the total amount, but this amount cannot be less than 1% of the Minimum Salary(MS).',
            info:'In this tariff, the supplier is offered the following additional services: receiving orders, their moderation, inclusion in the database of goods and / or services, implementation of goods and / or services (preparation of draft supply contracts, invoices and corresponding messages by software), making payments for supplies (cash / non-cash, at the expense of bank loans), representing the results of sales in warehouse accounting, as well as studying demand (infinite times), sending offers to Buyers first (infinite times), passing moderation out of turn (infinite times), contacting the technical support service out of turn, integrating accounting with the system (for an additional fee), placing advertising of goods and / or services (on the Contractor\'s web page 2 times, on the Contractor\'s Telegram channel once a month), placing in the TOP list of Suppliers (permanently) through the smart-market.uz information system.'
        }
    }
};
