import axios from 'axios';
import NProgress from 'nprogress';

const silentRequest = '__silent'

const isRequestSilent = (request) => request.hasOwnProperty(silentRequest)

const startLoading = (request, store) => {
  if (!isRequestSilent(request)) {
    NProgress.start();
    // store.commit('SET_LOADING', true);
  }
}

const doneLoading = (response, store) => {
  if (!isRequestSilent(response.config)) {
    NProgress.done();
    // store.commit('SET_LOADING', false)
  }
}

const createAxiosInstance = (config) => {
  const defaultConfig = {
    store: null,
    baseURL: '/',
    httpErrorHandler: () => {}
  }

  const { 
    store, 
    baseURL, 
    httpErrorHandler 
  } = Object.assign({}, defaultConfig, config)

  const axiosInstance = axios.create({ baseURL });

  axiosInstance.interceptors.request.use((request) => {
    startLoading(request, store)
    return request;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      doneLoading(response, store)
      return response;
    }, 
    (error) => {
      doneLoading(error, store)
      httpErrorHandler(error.response.status)
      return Promise.reject(error)
    }
  );

  return axiosInstance
}

export {createAxiosInstance}