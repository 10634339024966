import Vue from "vue"

export default {
  namespaced: true,
  state: {
    categories: [],
    isCategoriesMenuOpen: false
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    TOGGLE_CATEGORIES_MENU(state) {
      state.isCategoriesMenuOpen = !state.isCategoriesMenuOpen
    },
    CLOSE_CATEGORIES_MENU(state) {
      state.isCategoriesMenuOpen = false
    },
  },
  getters: {
    nestedCategories(state) {
      let h = {},
          r = [];
      state.categories.forEach(x => (h[x.id] = x, x.items = []));
      state.categories.forEach(x => x.parent_id ? h[x.parent_id]?.items.push(x) : r.push(x));
      return r;
    },
   
    getCategoryById: (state) => (id) => {
      
      let category  =state.categories.find(category => category.id === parseInt(id)) 
      return category
    }
  },
  actions: {
    list({commit, rootState}) {
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/sync/frontend/catalogs?code=6', {
          params: {
            lang: rootState['Account'].account.user.locale,
          },
          __silent: true
        }).then((response) => {
          commit('SET_CATEGORIES', response.data.data.categories)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
  },
}