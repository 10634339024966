import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  // },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Registration.vue')
  },
  {
    path: '/products/:categoryId',
    name: 'productsList',
    component: () => import(/* webpackChunkName: "products-list" */ '../views/ProductsList.vue')
  },
  {
    path: '/product-detail/:productId',
    name: 'productDetail',
    component: () => import(/* webpackChunkName: "product-detail" */ '../views/ProductDetail.vue')
  },
  {
    path: '/product-grade/:productId',
    name: 'productGrade',
    component: () => import(/* webpackChunkName: "product-detail" */ '../views/HistoryProductdetails.vue')
  },
  {
    path: '/pages/:slug',
    name: 'page',
    component: () => import(/* webpackChunkName: "product-detail" */ '../views/Page.vue')
  },
  {
    path: '/new-page/:pageName',
    name: 'newPage',
    component: () => import(/* webpackChunkName: "product-detail" */ '../views/NewPage.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import(/* webpackChunkName: "cart" */ '../views/Cart.vue')
  },
  {
    path: '/compair',
    name: 'compair',
    component: () => import(/* webpackChunkName: "cart" */ '../views/Compair.vue')
  },
  {
    path: '/plastic-card',
    name: 'plasticCard',
    component: () => import(/* webpackChunkName: "cart" */ '../views/PlasticCard.vue')
  },
  // {
  //   path: '/orders',
  //   name: 'orders',
  //   component: () => import(/* webpackChunkName: "orders" */ '../views/Orders.vue')
  // },
  {
    path: '/profile/personal-data',
    name: 'profilePersonalData',
    component: () => import(/* webpackChunkName: "profilePersonalData" */ '../views/profile/PersonalData.vue')
  },
  {
    path: '/profile/orders',
    name: 'profileOrders',
    component: () => import(/* webpackChunkName: "profileOrders" */ '../views/profile/Orders.vue')
  },
  {
    path: '/profile/notifications',
    name: 'profileNotifications',
    component: () => import(/* webpackChunkName: "profileNotifications" */ '../views/profile/Notifications.vue')
  },
  {
    path: '/profile/contracts',
    name: 'profileContracts',
    component: () => import(/* webpackChunkName: "profileNotifications" */ '../views/profile/documents/Contracts.vue')
  },
  {
    path: '/profile/invoices',
    name: 'profileInvoices',
    component: () => import(/* webpackChunkName: "profileNotifications" */ '../views/profile/documents/Invoices.vue')
  },
  {
    path: '/profile/cash',
    name: 'profileCash',
    component: () => import(/* webpackChunkName: "profileNotifications" */ '../views/profile/documents/Cash.vue')
  },
  {
    path: '/profile/approved',
    name: 'ApprovedProducts',
    component: () => import(/* webpackChunkName: "profileNotifications" */ '../views/profile/ApprovedProducts.vue')
  },
  {
    path: '/profile/confirm/:id',
    name: 'Confirm',
    component: () => import(/* webpackChunkName: "profileNotifications" */ '../views/profile/documents/Confirm.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import(/* webpackChunkName: "chekout" */ '../views/Checkout.vue')
  },
  {
    path: '/wishlist',
    name: 'wishlist',
    component: () => import(/* webpackChunkName: "wishlist" */ '../views/profile/Wishlist.vue')
  },
  {
    path: '/osync',
    name: 'osync',
    component: () => import(/* webpackChunkName: "osync" */ '../views/OSync.vue')
  },
  {
    path: '/bot/product/:id',
    name: 'ProductBot',
    component: () => import(/* webpackChunkName: "product-detail" */ '../views/ProductBot.vue')
  },
  {
    path: '/tariffs',
    name: 'tariffs',
    component: () => import(/* webpackChunkName: "product-detail" */ '../views/Tariffs.vue')
  },
  {
    path: '/bot/me',
    name: 'botMe',
    component: () => import(/* webpackChunkName: "product-detail" */ '../views/bot/me/index.vue')
  },
  {
    path: '/bot/contracts',
    name: 'botContracts',
    component: () => import(/* webpackChunkName: "product-detail" */ '../views/bot/me/contracts.vue')
  },
  {
    path: '/bot/cash',
    name: 'botCash',
    component: () => import(/* webpackChunkName: "product-detail" */ '../views/bot/me/cash.vue')
  },
  {
    path: '/oneid/check',
    name: 'OneIdCheck',
    component: () => import(/* webpackChunkName: "product-detail" */ '../views/OneIdCheck.vue')
  },
]

const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
