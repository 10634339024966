import Vue from "vue"

export default {
  namespaced: true,
  state: {
    orders: []
  },
  mutations: {
    SET_ORDERS(state, orders) {
      state.orders = orders
    },
    CLEAR_ORDERS(state) {
      state.orders = []
    }
  },
  actions: {
    getOrders({ commit }) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_ORDERS')
        Vue.axios.get('api/v3/frontend/custom/products').then((response) => {
          commit('SET_ORDERS', response.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    }
  },
}