import Vue from "vue";

const initialAccount = {
  token: false,
  user: {
    locale: "en",
    oblId: 26,
    areaId: 2601,
  },


};

export default {
  namespaced: true,
  state: {
    account: { ...initialAccount },
    showGlobalMessage: true,
    register_data: false,
    viewOption: "card",
    money_count:0,
    history:{
      type:'',
      action:'',
      type_id:'',
      image:'',
      name:''
    }
  },
  getters: {
    isAuthenticated(state) {
      return !!state.account.token;
    },
    my_money(state){
      return state.money_count
    }
  },
  mutations: {
    SET_ACCOUNT(state, account) {
      state.account = account;
    },
    SET_HISTORY_NAME(state, data){
      state.history[data.key]=data.value
    },
    SHOW_GLOBAL_MESSAGE(state, value) {
      state.showGlobalMessage = value;
    },
    CHANGE_LANG(state, lang) {
      state.account.user.locale = lang;
    },
    CHANGE_VIEW_OPTION(state, viewOption) {
      state.viewOption = viewOption;
    },
    CHANGE_REGION(state, { region_id, district_id }) {
      state.account.user.oblId = region_id;
      state.account.user.areaId = district_id;
    },
    CHANGE_PHONE(state, mobilePhone) {
      state.account.user.mobilePhone = mobilePhone
    },
    SET_REGISTER_DATA(state, data) {
      state.register_data = data;
    },
    RESET_REGISTER_DATA(state) {
      state.register_data = false;
    },
    LOGOUT(state) {
      state.account = { ...initialAccount };
    },
    SET_MONEY(state, value){
      state.money_count=value
    }
  },
  actions: {
    eimzo({ commit }, sign) {     
      return new Promise((resolve, reject) => {
        resolve([])
        // commit('SET_LOADING', true, {root: true})
        // Vue.axios.post("auth/eimzo", sign, { headers: { "Content-Type": "text/plain" } })
        //   .then((response) => {
        //     commit("SET_ACCOUNT", response.data); 
        //     resolve(response.data);
        //   })
        //   .catch((error) => {
        //     reject(error.response);
        //   })
        //   .finally(()=>{
        //     commit('SET_LOADING', false, {root: true})
        //   })
      });
    },
    oCreditAuth({ commit, dispatch }, credentials) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.post("api/v1/auth/login", credentials)
          .then((response) => {
            commit("SET_ACCOUNT", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
      });
    },
    changeLocale({ commit }, locale) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.get("auth/change_user_locale/", {
            params: { locale },
          })
          .then(() => {
            commit("CHANGE_LANG", locale);
            resolve(locale);
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
      });
    },
    changeRegion({ commit, state }, { region_id, district_id }) {
      return new Promise((resolve, reject) => {
        if (state.account.token) {
          commit('SET_LOADING', true, {root: true})
          Vue.axios.put("api/v1/frontend/user/change", { region_id, district_id })
            .then(() => {
              commit("CHANGE_REGION", { region_id, district_id });
              resolve();
            })
            .catch((error) => {
              reject(error.response);
            })
            .finally(()=>{
              commit('SET_LOADING', false, {root: true})
            })
        } else {
          commit("CHANGE_REGION", { region_id, district_id });
          resolve();
        }
      });
    },
    register(_, credentials) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.post("api/v1/auth/register", credentials)
          .then((response) => {
            resolve();
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
      });
    },
    sendVerification(_, phonenumber) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.post("api/v1/phone/verify/register", { phone_number: phonenumber })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
      });
    },
    verifyPhone(_, data) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.post("api/v1/phone/verify", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response);
          })
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })

      });
    },
    setMoney({getters, commit}){
      if(getters.isAuthenticated){ 
        return new Promise((resolve, reject) => { 
          // Vue.axios.get('api/v1/sync/familycredit/applications').then((response) => { 
          //   console.log(response.data.data)
          //   let m=0
          //   if(response.data&&response.data.data && response.data.data.length){
          //     response.data.data.forEach(element => {
          //       m=m+parseFloat(element.available_sum)
          //     });
          //   }
           
          //   commit("SET_MONEY", m);
          //   resolve(response.data)
          // }).catch((error) => {
          //   commit("SET_MONEY", 0);
          //   reject(error.response)
          // })
          commit("SET_MONEY", 0);
          resolve([])
        })
      }

    },
    setHistory({getters, state}, data){
      if(getters.isAuthenticated){ 
        let history={
          ...state.history,
          ...data
        }
        return new Promise((resolve, reject) => { 
          Vue.axios.post('api/v1/user/data/history', history).then((response) => {  
            resolve(response.data)
          }).catch((error) => { 
            reject(error.response)
          })
        })
      }
    }
  },
};
