export default {
  lang: 'Ўзбекча',
  interface: {
    unknown_erorr: 'Номаълум хато: {error}',
    error_occurred: 'Хатолик юз берди',
    error_message: 'Операция давомида хатолик юз берди. Илтимос, ушбу муаммони ҳал қилиш учун техник ёрдамга мурожаат қилинг.',
    fill_required_fields: '(*) Белгиланган барча майдонларни тўлдиринг',
    more: 'Батафсил',
    hide: 'Йиғиш',
    edit: 'Ўзгартириш',
    choose: 'Танлаш',
    back: 'Орқага',
    pagination_back:'Орқага',
    forward: 'Олдинга',
    wishlist: 'Танланганлар',
    remove: 'Ўчириш',
    cancel: 'Бекор қилиш',
    save: 'Сақлаш',
    are_you_sure: 'Ишончингиз комилми?',
    understand: 'Тушунарли',
    search: 'Қидириш',
    all_search_results: 'Барча қидирув натижалари',
    avarage_costs: 'Smart-market.uz савдо майдонидаги ўртача нархлар',
    seen_results: 'Сиз {total} та натижадан {viewedItems} тасини кўрдингиз',
    search_undefined:"Қидирув мезонига мос маълумот топилмади!",
    yes:"Ҳа",
    no:"Йўқ"
  },
  navigation: {
    seller_cabinet: 'Шахсий кабинет',
    orders: 'Буюртмалар',
    my_orders: 'Буюртмаларим',
    my_cards: 'Карталар',
    notifications: 'Хабарномалар',
    wishlist: 'Танланганлар',
    cart: 'Сават',
    logout: 'Чиқиш',
    login: 'Кириш',
    main: "Бош саҳифа",
    category: "Категория",
    profile: "Профиль",
    documents: "Ҳужжатлар",
    contracts: "Шартномалар",
    invoices: "Ҳисоб-фактуралар",
    all_categories: 'Барча тоифалар',
    search_placeholder: 'Маҳсулот номи ёки id рақамини киритинг',
    search_button: 'Қидириш',
    delivery_to: 'Сизнинг манзилингиз <span>{place}</span>',
    choose_delivery: 'Туман/шаҳарни танланг',
    choose_delivery_message: 'Сизга энг яқин ва мос келадиган маҳсулот ва сотувчиларни кўрсатишимиз учун сиз жойлашган туман/шаҳарни танланг.',
    help: 'Телеграм орқали мурожаат қилиш',
    old_version: "Эски версияга ўтиш",
    back_to_main:'Асосий саҳифага ўтиш',
    all:'Барчаси',
    confirmed_products:'Тайёр махсулотлар',
  },
  footer: {
    subscribe_text: 'Электроника, болалар ва уй учун маҳсулотлар бўйича чегирмалардан хабардор бўлинг', 
    subscribe: 'Обуна бўлиш',
    email_placeholder: 'Электрон почта манзилингизни ёзинг',
    follow_us: 'Бизни ижтимоий тармоқларда кузатиб боринг',
    copyright: '© 2021-2023 — «Smart Marketplace» МЧЖ. Барча ҳуқуқлар ҳимояланган.',
    report_problem: 'Сайтдан фойдаланишда муаммолар борми?',
    stir: "СТИР",
    mfo: "МФО",
    xr: "ҲP",
    branch:"МЧЖ",
    goal_of_payment: "Тўлов мақсади",
    offert: "Оммавий офертага асосан гаров пули кўчирилди",
    bank_branch: "Шайхонтохур филиали",
    offert_key_title: "Оммавий оферта шарти асосида",
    offert_info_text: '4.5. Закалат пули таъминотчининг ШАХСИЙ ҳисоб - рақамидан пул кўчириш йўли (ПEРEЧИСЛEНИE) билан "SMART MARKETPLASE" МЧЖнинг ҳисоб рақамига кўчирилади (аксарият ҳолатларда тизимдаги жараёнлар вақти одатдагидан чўзилади)',
  },
  routes: {
    home: 'Бош саҳифа',
    cart: 'Сават',
    login: 'Тизимга кириш',
    register: 'Рўйхатдан ўтиш',
    orders: 'Буюртмалар',
    plasticCard: 'Карталар',
    wishlist: 'Танланганлар',
    checkout: 'Буюртмани расмийлаштириш',
    products: 'Махсулотлар',
    personalData: 'Шахсий маълумот',
    notifications: 'Хабарнома',
    contracts: 'Шартнома',
    invoice: 'Ҳисоб-фактура',
    compair:'Таққослаш',
    cash_money:"Нақд пул",
    order_confirmation: "Буюртмани тасдиқлаш",
  },
  plastic_card: {
    add_card_title: "Янги карта қушиш",
    enter_card_title: "Янги карта киритиш",
    choose_card_view_title: "Карта кўринишини танланг",
    choose_card_type_title: "Карта турини танланг",
    enter_card_number_title: "Карта рақамини киритинг",
    expiry_date_title: "Яроқлилик муддати",
    main_card_title: "Асосий карта",
    enter_card_name_title: "Карта номини киритинг",
    save_btn_title: "Сақлаш",
    confirmSmsTitle: "Смс кодни тасдиқлаш",
    enter_sms_code_title: "Телефон рақамингизга юборилган кодни киритинг",
    not_confirmed_txt: "Тасдиқланмаган",
    please_confirm_txt: "Илтимос картангизни тасдиқланг",
    confirm_txt: "Картани тасдиқлаш",
    delete_modal_title: "Картани ўчириш",
    confirm_delete_card_title: "Диққат картани ўчиришни тасдиқлайсизми?",
    delete_card_btn: "Ўчириш",
    exist_card_txt: "Бу карта базада бор!",
    wrong_code_txt: "Киритилган код нотўғри",
    retry_code_txt: "Кодни қайта юбориш",
    not_available: "Мавжуд эмас"
  },
  login: {
    wrong_credentials: 'Киритилган маълумотларнинг тўғрилигини текширинг',
    fill_all_inputs: 'Барча майдонларни тўлдиринг',
    not_registred: 'Сиз рўйхатдан ўтмагансиз',
    button_text: 'Кириш',
    esign: 'ЭРИ орқали кириш',
    choose_key: 'Рўйхатдан калитни танланг',
    date: 'Сана',
    auth_required: 'Тизимга кириш талаб қилинади',
    auth_required_message: 'Давом этиш учун тизимга киришингиз керак.',
    auth_action_text: 'Тизимга кириш',
    type:'Тизимга кириш учун ўзингиз учун маъқул усулни танланг',
    auth_application:'Aриза рақами орқали кириш',
    checking:"Маълумотлар текширилмоқда",
    one_id:"One Id орқали кириш",
    error:"Тизимга киришда хатолик юз берди!",
    login:"Логин",
    password:"Пароль",
    error_login_password:"Логин ёки пароль хато!"
  },
  esign: {
    keys_not_found: 'Электрон калитлар топилмади',
    client_not_found: 'E-IMZO-ни улашда хатолик юз берди. Браузер ёки E-IMZO модулини ўрнатинг.',
    wrong_password: 'Паролингиз тўғри ёки йўқлигини текширинг',
    eimzo_site: 'E-IMZO веб-сайти',
    tin: 'СТИР',
    pinfl: 'ЖШШИР',
    cert_expired: 'Муддати ўтган',
    cert_active: 'Фаол',
    cert_type_entity: 'Жисмоний шахс',
    cert_type_individual: 'Жисмоний шахс',
  },
  register: {
    passwords_mismatch: 'Киритилган пароллар мос келмади',
    incorrect_phonenumber: 'Тўғри телефон рақамини киритинг'
  },
  orderStates: {
    CONTRACT_CREATED: 'Шартнома тайёрланди',
    CONTRACT_IN_PROCESS: 'Шартномани таъминотчи имзолади',
    CONTRACT_CANCELED: 'Шартнома тузиш бекор қилинди',
    CONTRACT_REJECTED: 'Шартнома имзолаш рад этилди',
    CONTRACT_FULL_SIGNED: 'Шартнома тўлиқ имзоланди'
  },
  delivery_types: {
    bring: 'Сотувчининг омборидан олиб кетиш',
    delivery: 'Курьер орқали етказиб бериш',
    choose: 'Етказиб бериш усулини танланг'
  },
  delivery_types_short: {
    bring: 'Олиб кетиш',
    delivery: 'Етказиб бериш',
  },
  product: {
    card_delivery: 'Етказиб бериш',
    add_to_cart: 'Саватга қўшиш',
    already_in_cart: 'Саватга қўшилди',
    added_to_cart: 'Маҳсулот саватга қўшилди',
    added_to_cart_message: 'Сиз буюртмани расмийлаштириш учун ҳозироқ ёки харидларингизни якунлагандан кейин саватга киришингиз мумкун.',
    continue_shopping: 'Харидларни давом эттириш',
    move_to_cart: 'Саватга ўтиш',
    delivery_days: 'Етказиб бериш буюртма тасдиқлангандан бошлаб {days} кун ичида сотувчи томонидан амалга оширилади',
    delivery_type: 'Етказиб бериш усули',
    free: 'Бепул',
    choose_delivery_type: 'Етказиб бериш усулини танланг',
    delivery_select_label: 'Сизга маҳсулотни қандай тарзда олган қулай?',
    delivery_address: 'Етказиб бериш манзили',
    bring_select_label: 'Қабул қилиш масканини танланг',
    short_info: 'Маҳсулот ҳақида қисқача',
    unit: 'Ўлчов бирлиги',
    min_amount: 'Энг кам миқдори',
    expiration_life: 'Амал қилиш муддати',
    free_service_life: 'Бепул сервис хизмати',
    ayear: 'Ишлаб чиқарилган йил',
    country_name: 'Ишлаб чиқарувчи мамлакат',
    make_name: 'Ишлаб чиқарувчи',
    description: 'Тавсиф',
    delivery_unaviable: 'Сизнинг ҳудудингизда мавжуд эмас',
    bring_addresses: '{count} чиқариш маскани(лари)',
    id_number: 'ID рақам',
    same_products: 'Ўхшаш маҳсулотлар',
    detail_info: 'Батафсил маълумот',
    comment: 'Шарҳлар',
    leave_feedback: 'Изоҳ қолдириш',
    leave_comment_about_product_title: 'Маҳсулот ҳақида фикр қолдириш',
    leave_comment_about_product: 'Маҳсулот ҳақида фикр қолдиринг *',
    you_commented: 'Сиз ушбу маҳсулотга изоҳ қолдиргансиз!',
    you_need_buy_product: 'Изоҳ қолдириш учун маҳсулот ҳарид қилишиниз керак!',
    you_need_login: 'Изоҳ қолдириш учун тизимга киришингиз керак! ',
    good_product: "Яхши маҳсулот",
    bad_product: "Қониқарсиз маҳсулот",
    comment_txt: "Изоҳ",
    good_txt: "Яхши",
    bad_txt: "Ёмон",
    detail_not_found: "Маҳсулот топилмади",
    asaxiy_products: 'Asaxiy маҳсулотлари',
    new_product:'Янги',
    base_amount:"Бор-йўғи {count} {unit} қолди",
    week_sold_amount:'Бу ҳафтада {count} киши сотиб олди',
    week_sold_first:"Бу ҳафтада биринчи бўлиб сотиб олинг",
    market_adresses:"Дўкон манзиллари",
    market_adresses_title:"Махсулот қуйидаги дўконларимизда мавжуд",
    available:"Мавжуд",
    buy_one:"Бир кликда сотиб олиш",
    buy_one_buy:"Харид қилиш",
    shipping_type:'Қабул қилиш усуллари',
    payment_type:"Тўлов усуллари",
    copy_data:"Маълумот кўчирилди",
    share:"Улашиш"
  },
  seller: {
    reviews: '{count} фикр-мулоҳазалар',
    show_phonenumber: 'Телефон рақамини кўриш'
  },
  address: {
    new: 'Янги манзил',
    region: 'Ҳудуд',
    district: 'Туман',
    address_input_label: 'Манзилни кўрсатинг',
    comment_label: 'Изоҳ'
  },
  wishlist: {
    empty_text: 'Маҳсулот саҳифасида ♡ тугмасини босинг ва ўзингизга ёққан махсулотни шу ерга қўшинг.',
    empty_title:"Танланганлар рўйхати бўш",
    empty_message:"Маъқул келган махсулотларни кейинроқ кўриш ёки ҳарид қилиш учун танланганлар рўйхатига киритинг."
  },
  confirmed:{
    empty_title:"Тайёр махсулотлар мавжуд эмас!",
    empty_text:"Тайёр махсулотлар мавжуд эмас! Улар тасдиқлангандан сўнг ушбу ойнада кўринади."
  },
  orders: {
    empty_text: 'Буюртмаларингиз мавжуд эмас.',
    order_proccess: "Буюртма № {document_id} жараёни",
    performed: "Бажарилди",
    not_performed: "Бажарилмади",
    in_progress: "Жараёнда",
    pending: "Кутилмоқда",
    make_order: "Буюртма қилиш",
    order_confirmation: "Буюртмани тасдиқлаш",
    order_delivery: "Буюртмани етказиш",
    order_finish: "Буюртмани якунлаш",
    unpassed_stage: "Ўтилмаган босқич",
    orders_story: "Буюртмалар тарихи",
    product_txt: "Маҳсулот",
    amount: "Миқдори",
    order_rejected: "Бекор қилинган",
    order_delivered: "Якунланган",
    search_input_placeholder: "Маҳсулот номини киритинг",
    confirm:'Тасдиқлаш',
    send_confirm_code:"Тасдиқлаш коди сизнинг телефонингизга смс тарзида юборилди!",
    re_send:"Қайта юбориш"
  },
  cart: {
    empty_text: 'Сизнинг саватингиз бўш.',
    total: 'Жами',
    products_count: 'Маҳсулот, {count} дона',
    delivery: 'Етказиб бериш',
    remove_confirm_text: 'Ҳақиқатдан ҳам бу маҳсулотни саватдан олиб ташламоқчимисиз?',
    checkout_button_text: 'Буюртмани расмийлаштириш',
    choose_org:"Буюртмани расмилаштириш учун корхонани танланг",
    org:"Ташкилотлар",
    empty_title:"Саватчада ҳозирда ҳечнима йўқ",
    empty_message:"Мақул келган махсулотларни харид қилиш учун саватчага қўшинг."
  },
  category: {
    products_count: '{count} маҳсулот(лар)',
    empty_text: 'Қидирув мезонингизга мос маҳсулотлар топилмади.'
  },
  checkout: {
    delivery_unaviable: 'Ушбу йўналишда товарларни етказиб бериш амалга оширилмайди',
    payment_type: 'Тўлов усули',
    pay_button: 'Буюртма учун пул тўлаш',
    choose_payment_type_txt: "Танланг",
    family_credit:"Оила кредит",
    choose_delivery_type: 'Етказиб бериш усулини танланг',
    choose_delivery_type_message: 'Шошилманг, аввал сиз ўзингиз учун қулай етказиб бериш усулини танлашингиз керак',
    insufficient_funds: 'Етарли маблағ мавжуд эмас',
    insufficient_funds_message: 'Расмийлаштирилган кредит суммаси олинаётган товар ёки хизмат учун етарли эмас',
    force_pay: 'Ўз маблағимдан фарқини тўлаш',
    attention_not_enough_money:"Диққат буюртмани расмийлаштириш учун ҳисобингизда йетарли маблағ мавжуд емас!",
    hight_price:"Киритилган пул миқдори буюртма нархидан баланд!",
    enter_family_credit:"Оилакредит ариза шартномасидан ажратилган кредит миқдоридан ташқари қўшимча тўлов турлари киритилди",
    additional_payment:"Қўшимча тўлов турини танланг:",
    accept_application:"Сизниг аризангиз қабул қилинди",
    pay_cash_question:"Сиз ушбу буюртма учун тўловни нақд пулда амалга оширмоқчимисиз?",
    accaunt_isnot_full:`Буюртмани расмийлаштиришда давом эттириш учун <a href="{link}" class="link">шахсий маълумотларингизни  </a> тўлдиришингиз керак.`
  },
  payment_types: {
    credit: 'Оилакредит кредитини расмийлаштириш',
    cash: 'Нақд пул орқали'
  },
  ocredit: {
    aplications_empty: `Сизда амалдаги шартномалар мавжуд эмас, давом эттириш учун <a class="link" href="{link}" target="_blank">шартномани расмийлаштришингиз</a> керак.`,
    login: 'Тизимга кириш',
    login_message: 'Кредит олиш аризангиздаги маълумотларни киритинг',
    login_info: 'Ариза рақами ва санасини киритинг.',
    auth_error: 'Кредит шартнома имзоланмаган'
  },
  home: {
    banner0:{
      title1:'Янги йил - бу янги бизнес',
      title2:'<span>Янги</span> йилингиз билан',
      text:"Янги йилда ўз оилавий бизнесингизга эга бўлишни хоҳлайсизми унда SMART-MARKET.UZ бу учун энг яхши танлов."
    },
    banner1:{
      title1:'ЭНДИ ASAXIY МАҲСУЛОТЛАРИ',
      title2:'<span>SMART-MARKET.UZ</span> ДА',
      text:'Исталган турдаги ASAXIY иловаси маҳсулотларини SMART-MARKET.UZ орқали харид қилинг.'
    },
    banner2:{
      title1:"ЎЗ БИЗНEСИНГИЗГА ЭГА БЎЛИНГ",
      title2:'<span>SMART-MARKET.UZ</span> СИЗ БИЛАН',
      text:"Оилавий бизнесингизга тез ва осон эга бўлинг ёки бизнесингизни биз билан ривожлантиринг."
    },
    banner3:{
      title1:"ФEРМEР БЎЛМОҚЧИМИСИЗ?",
      title2:'<span>БИЗ</span> СИЗ УЧУН ҲАРАКАТ ҚИЛАМИЗ',
      text:"Шахсий фермер хўжалигингиз бўлишини хоҳлайсизми? Биз билан бу энди муаммо эмас."
    },
    welcome: '<div class="big">Smart Marketга</div> <div>Хуш келибсиз</div>',
    sponsor: 'дастур томонидан қўллаб-қувватланади',
    ocredit_login_button: 'Кредит олиш аризангиз рақами билан тизимга киринг',
    are_you_seller: 'Маҳсулот сотасизми?',
    start_selling: 'Бизда сотишни бошланг',
    more: 'барчаси',
    ocredit_view_products: 'Кредит ариза бўйича маҳсулотларни кўриш',
    telegram_message: 'Телеграм каналимиздаги сайтдан фойдаланиш бўйича йўриқномалар билан танишиб чиқинг.',
    telegram_action: 'Телеграм каналга ўтиш',
    handbook: 'Видео йўриқномалар',
    main_slider_title: '<h1 class="main_slider_heading">Ўз оилавий <span>бизнесингизни</span> <br />Яратинг</h1>',
    main_slider_asaxiy_title:
    '<h1 class="main_slider_heading">Энди <span>Asaxiy</span> Маҳсулотлари <br /> <span>SMART-MARKET.UZ  да!</span></h1>',
    main_slider_description: "Ўз ғояларингизни ҳаётга татбиқ этинг",
    continue_to_buy: "Харидни давом эттириш учун тизимга киринг",
    do_you_buy: "Маҳсулот сотасизми?",
    connect_to_system: "Тизимга уланинг",
    main_categories: "Бош тоифалар",
    average: "Маҳсулот ва хизматларнинг ўртача нархи",
    see_all: "Барчасини кўриш",
    high_products_title: "Истеъмолчилар томонидан юқори баҳоланган маҳсулот ва хизматлар",
    best_products_title:"Энг кўп сотилган маҳсулотлар",
    cheap_products_title: "Энг арзон маҳсулотлар (йўналишлар бўйича)",
    new_products_title: "Янги қўшилган маҳсулотлар",
    bannerTitle: "МОБИЛ ИЛОВАДА СEВИМЛИ МАҲСУЛОТРАИНГИЗНИ СОТИБ ОЛИНГ, ВА 20% ГАЧА САҚЛАНГ!",
    qrCodeTitle: "Юклаб олиш учун камерангизни ҚР кодга қаратинг",
    detailedTitle: "Батафсил",
    simple_with_us:"БИЗ БИЛАН БАРЧАСИ ЖУДА ТЕЗ ВА ОСОНРОҚ!",
    wait_you:"ЭНГ АРЗОН НАРХЛАР СИЗНИ КУТМОҚДА!"
  },
  banners:{
    with_us:"Биз билан бирга!"
  },
  sorting_types: {
    popular: 'машҳурлиги бўйича',
    price: 'нархи бўйича',
    rating: 'рейтинги бўйича',
    review: 'шарҳлар бўйича',
    discount: 'чегирма бўйича',
    new: 'янгилиги бўйича',
  },
  subscribe: {
    title: 'Обуна бўлганингиз учун раҳмат',
    description: 'Энг сўнгги чегирмалар ва янгиликлар учун почта қутингизни текширинг.'
  },
  issue_report: {
    title: 'Муаммо ҳақида хабар бериш',
    description: 'Муаммоингизни иложи борича батафсил кўрсатинг',
    name: 'Исмингиз?',
    phonenumber: 'Телефон рақамингиз',
    describe_problem: 'Муаммоингизни тасвирлаб беринг',
    send: 'Жўнатиш',
    success: 'Сизнинг аризангиз муваффақиятли юборилди!',
    wait_until_call: 'Биз аллақачон сўровингизни кўриб чиқмоқдамиз, илтимос, операторимиз қўнғироқларини кутинг.'
  },
  filters: {
    category: 'Тоифа',
    price: 'Нархи',
    from: 'дан, сўм',
    to: 'гача, сўм',
    available_for_credit: 'Кредитга мавжуд',
    credit_tip: '«Oila kredit» дастури билан сотиб олиш',
    delivery_types: 'Етказиб бериш тури',
    delivery: 'Етказиб берувчи орқали',
    bring: 'Сотиб олувчи олиб кетади',
    by_contracts: 'Аризадаги махсулотларни кўрсатиш',
    all_regions: 'Ҳамма ҳудудлар бўйича',
    region: 'Ҳудуд',
    see_more_txt: "Кўпроқ кўрсатиш",
    see_less_txt: "Камроқ кўрсатиш",
    additional_filters: "Қўшимча филтрлар",
    seller_txt: "Таъминотчилар",
    clear_filters: "Фильтрни тозалаш",
    providers_not_found: "Қидирув мезонингизга мос таъминотчилар топилмади",
    sort:"Тартиблаш",
    filter:'Фильтрлар',
    show:'Кўриш',
    clear:'Тозалаш'
  },
  personal_data: {
    phone_number: "Телефон рақам",
    backup_phone_number: "Қўшимча телефон рақам",
    telegramId: "Телеграм ID",
    email_box: "Электрон почта",
    save_btn: "Ўзгаришларни сақлаш",
    email_placeholder: "Почтангизни киритинг",
    message_type: "Хабарнома юбориш усули",
    sms: "Смс",
    telegram_bot: "Телеграм бот",
    email: "Email",
    get_id_info: "ID олиш учун ушбу тугмага босинг",
    error_photo_title: "Илтимос расм киритинг",
    error_message_type: "Илтимос Хабарнома келиш усулини танланг",
    photo_validation_text: "Илтимос ҳажми 2 мб дан кам бўлган расм киритинг",
    id_validation: "ID олиш учун телефон рақам майдонини тўлдиринг!",
    id_not_found: "ID топилмади!",
    email_validation_text: "Илтимос email манзилни тўғри киритинг!",
    success_send: "Маълумотлар муваффақиятли сақланди!",
    message_connect_tg_bot: `<span>Бот орқали хабарнома олиш учун Ботга уланишингиз керак.<a class="pui-text is-primary" href="https://t.me/smart_market_rs_bot" target="_blank"> Ботга ўтиш</a></span>`,
    input_tg_bot: `<span>ID олиш учун Бот га уланишингиз керак.<a class="pui-text is-primary" href="https://t.me/smart_market_rs_bot" target="_blank"> Ботга ўтиш</a></span>`,
    add_new_adress: "+ Янги манзил қўшиш",
    delivery_addresses: "Етказиб бериш ҳудудларини киритинг",
  },
  documents: {
    empty_contracts: "Шартномалар мавжуд эмас",
    provider: "Таъминотчи",
    contract_txt: "Шартнома",
    signed_day: "Имзоланган сана",
    state: "Ҳолати",
    count_txt: "Рақам",
    date_txt: "Сана",
    sum_txt: "Сумма",
    seller_txt: "Сотувчи",
    buyer_txt: "Харидор",
    rejected: "Рад этилди",
    view: "Кўриб чиқилмоқда",
    created: "Қабул қилинди",
    in_process: "Имзолашда",
    in_process_confirmation:"Тасдиқлшада",
    canceled: "Бекор қилинди",
    rejected: "Рад этилди",
    full_signed: "Тўлиқ имзоланди",
    sys_canceled: "Система рад этди",
    cancel_view: "Бекор қилиш",
    contract_is_canceled:"Сизнинг шартномангиз бекор қилинди!",
    contract_is_canceled_message:"Сизнинг шартномангиз бекор қилинди, исталган вақтда биз сиз билан янги шартнома тузишга тайёрмиз.",
    cancel_contract:"Шартномани бекор қилиш",
    cancel_contract_right:"Сиз ушбу шартномани нима учун бекор қилмоқчисиз?",
    cancel_contract_reason:"Шартномани бекор қилиш сабаби",
    search_by_number_placeholder: "Шартнома рақам",
    seacrch_by_number_name:"Маҳсулот номи ёки шартнома рақам",
    canceled_date:'Бекор қилинган сана',
    payment:"Тўлов",
    cash_money:"Нақд пул",
    total_cash:"Жами сумма",
    confirm_orers:"Сизнинг буюртмангиз муваффақиятли расмийлаштирилди!",
    confirm_orers_text:"Сизнинг буюртмангиз расмийлаштирилди нақд пул бўлимидан буюртма чекини кўришингиз мумкин!",
    order_is_canceled:"Сизнинг буюртмангиз бекор қилинди!",
    order_is_canceled_message:"Сизнинг буюртмангиз бекор қилинди, исталган вақтда қайта буюртма беришингиз мумкин.",
    cancel_order:"Буюртмани бекор қилиш", 
    cancel_order_right:"Сиз ушбу буюртмани нима учун бекор қилмоқчисиз?",
    cancel_order_reason:"Буюртмани бекор қилиш сабаби",
  },
  invoices: {
    empty_invoices: "Фактуралар мавжуд эмас",
    corporation_txt: "Корхона",
    customer: "Мижоз",
    helper_hokim: "Ҳоким ёрдамчиси далолатномаси",
    result: "Натижа",
    search_by_number_placeholder: "Фактура рақамини киритинг",
  },
  notifications: {
    empty_notification: "Янги хабарномалар мавжуд эмас",
    empty_reads_notification: "Ўқилган хабарномалар мавжуд эмас",
    newest: "Янгилари",
    olders: "Олдингилари",
  },
  tariffs:{
    name:"Тарифлар",
    price:'Хизмат нархи:',
    info:'Xizmat ҳақида:',
    SILVER:{
      price:'Харидор va Таъминотчи ўртасидаги оферта суммасининг 1 фоизи миқдорини ташкил қилади лекин ушбу сумма БҲМнинг 1 фоизидан кам бўлиши мумкин эмас.',
      info:'Ушбу тарифда Таъминотчига таклифларини қабул қилиш, модерациядан ўтказиш,  товарлар ва /ёки хизматлар маълумотлар базасига киритиш), товарлар ва/ёки хизматларни реализация қилиш (етказиб бериш бўйича шартномалар лойиҳалари, ҳисоб-фактуралар ҳамда тегишли хабарномаларни  дастурий тарзда тайёрлаш), етказиб берилган товарлар ва/ёки хизматлар учун ҳисоб-китобни амалга ошириш (нақд/нақд пулсиз, банк кредитлари воситасида), реализация натижаларини омборхона ҳисобида акс эттириш ҳамда талабни ўрганиш (ойига 30 та талаб), Таъминотчилар ТОП рўйхатига жойлаштириш (ойига 5 кун) бўйича қўшимча хизматлар кўрсатилади.'
    },
    GOLD:{
      price:'Харидор ва Таъминотчи ўртасидаги оферты суммасининг 2 фоизи миқдорини ташкил қилади лекин ушбу сумма БҲМнинг 1 фоизидан кам бўлиши мумкин эмас.',
      info:'Ушбу тарифда Таъминотчига таклифларини қабул қилиш, модерациядан ўтказиш,  товарлар ва /ёки хизматлар маълумотлар базасига киритиш), товарлар ва/ёки хизматларни реализация қилиш (етказиб бериш бўйича шартномалар лойиҳалари, ҳисоб-фактуралар ҳамда тегишли хабарномаларни  дастурий тарзда тайёрлаш), етказиб берилган товарлар ва/ёки хизматлар учун ҳисоб-китобни амалга ошириш (нақд/нақд пулсиз, банк кредитлари воситасида), реализация натижаларини омборхона ҳисобида акс эттириш ҳамда талабни ўрганиш (ойига 60 та талаб), таклифларни Харидорларга биринчи навбатда йўллаш (ойига 60 та таклиф), модерациядан навбатсиз ўтказиш(ойига 10 марта), Таъминотчилар ТОП рўйхатига жойлаштириш (ойига 10 кун) бўйича қўшимча хизматлар кўрсатилади.'
    },
    PLATINUM:{
      price:'Харидор ва Таъминотчи ўртасидаги оферта суммасининг 3 фоизи миқдорини ташкил қилади лекин ушбу сумма БҲМнинг 1 фоизидан кам бўлиши мумкин эмас.',
      info:'Ушбу тарифда Таъминотчига таклифларини қабул қилиш, модерациядан ўтказиш,  товарлар ва /ёки хизматлар маълумотлар базасига киритиш), товарлар ва/ёки хизматларни реализация қилиш (етказиб бериш бўйича шартномалар лойиҳалари, ҳисоб-фактуралар ҳамда тегишли хабарномаларни  дастурий тарзда тайёрлаш), етказиб берилган товарлар ва/ёки хизматлар учун ҳисоб-китобни амалга ошириш (нақд/нақд пулсиз, банк кредитлари воситасида), реализация натижаларини омборхона ҳисобида акс эттириш ҳамда талабни ўрганиш (чексиз), таклифларни Харидорларга биринчи навбатда йўллаш (чексиз), модерациядан навбатсиз ўтказиш(чексиз), техник ёрдамга навбатсиз боғланиш, бухгалтерия ҳисобини тизим билан интеграциялаштириш (алоҳида тўлов эвазига), товар ва хизматлар рекламаси (ойига Ижрочининг веб-саҳифасида 2 марта, Telegram каналида 1 марта), Таъминотчилар ТОП рўйхатига жойлаштириш (доимий) бўйича қўшимча хизматлар кўрсатилади.'
    }
  }
};
