<template></template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
  name: "DataPreloader",
  mounted() {
    // Восстанавливаем локаль
    // console.log('setting up locale')
    this.$locale.change(this.account.user.locale)

    // Если логаут ничего не делаем
    // this.$route.name не работает внутри mounted()
    if(window.location.pathname.includes('logout')) {
      return ;
    }

    // Прогружаем нужные ресурсы
    // console.log('downloading resources')
    let start = performance.now()
    Promise.all([
      this.$store.dispatch('Regions/list'),
      this.$store.dispatch('Categories/list'),
      this.$store.dispatch('Rubrics/getFooter'),
      this.account.token && this.$store.dispatch('Addresses/list')
    ]).then(() => {
      // console.log(`resources loaded (${Math.round(performance.now() - start)} ms)`)
    }).catch(() => {
      console.log(`resources doesnt loaded properly`)
    }).finally(() => {
      this.$store.commit('SET_LOADING', false)
      this.$emit('loaded')
    })
  },
  computed: {
    ...mapState('Account', ['account']),
    currentRoute() {
      return this.$route.name
    }
  },
}
</script>