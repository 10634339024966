import Vue from "vue"
import i18n from "@/core/i18n"

export default {
  namespaced: true,
  state: {
    regions: []
  },
  mutations: {
    SET_REGIONS(state, regions) {
      state.regions = regions.map((region) => ({
        ...region,
        districts: region.districts.sort((a, b) => a.name.localeCompare(b.name))
      })).sort((a, b) => a.name.localeCompare(b.name))
    },
  },
  actions: {
    list({commit}) {
      return new Promise((resolve, reject) => {
        Vue.axios.get('api/v1/sync/frontend/region', {
          params: {
            lang: i18n.locale
          },
          __silent: true
        }).then((response) => {
          commit('SET_REGIONS', response.data.data)
          resolve(response.data)
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
  },
}