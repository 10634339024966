import Vue from "vue"

export default {
  namespaced: true,
  state: {
    cart: [], 
    cart_seller:[],
    Price:10  
  },
  getters: {
    hasProduct: (state) => (id) => !!state.cart.find(product => product.id === id ||  product.product_id === id),
    productsCount(state) {  
      return state.cart.length
    },
    productsSellerCount(state) { 
      return state.cart_seller.length
    },
    productsPrice(state) {
      let price=0
      state.cart.forEach(element=>{
        if(element.amount && element.amount>element.min_amount){
          price=price+ Math.round(parseFloat(element.amount) * element.price)
        }
        else{
          price=price+ Math.round(parseFloat(element.min_amount) * element.price)
        }
      })
      return price
    },
    sellerDeliveryPrice(state){
      let price=0
      state.cart_seller.forEach(element=>{
        if(element.amount && element.amount>element.min_amount){
          price=price+ Math.round(parseFloat(element.amount) * element.price)
        }
        else{
          price=price+ Math.round(parseFloat(element.min_amount) * element.price)
        }
      })
      return price
    },
    isProductsDeliveryChoosen(state) {
      let isChoosen = true
      state.cart.forEach(product => {
        if (product.delivery && !product.delivery.type )
          isChoosen = false
      })
      return isChoosen
    }
  },
  mutations: {
    ADD(state, product) {

      state.cart = [...state.cart, {
        ...product,
        amount: product.min_amount,
        delivery: { type: null }
      }]
    },
    SET_AMOUNT(state, {id, amount}) {
      state.cart = state.cart.map((product) => {
        if (product.id === id) {
          return { ...product, amount }
        } else {
          return product
        }
      })
    },
    SET_PRICE(state, price){
      state.Price=price
    },
    SET_DELIVERY(state, {id, delivery}) {
      state.cart = state.cart.map((product) => {
        if (product.id === id) {
          return { ...product, delivery }
        } else {
          return product
        }
      })
    },
    REMOVE(state, id) {
      state.cart = state.cart.filter(product => product.id !== id)
    },
    CLEAR(state) {
      state.cart = []
    },
    SET_CARTLIST(state, cart) {
      state.cart = cart;
    },
    SET_SELLER_CART(state, cart){
      state.cart_seller = cart;
    },
    TOGGLE(state, product){ 
      let a=state.cart.filter(item => item.id === product.id) 
      if(a && a.length){
        state.cart = state.cart.filter(item => item.id !== product.id)
      }
      else{
        state.cart = [...state.cart, {
          ...product,
          amount: product.min_amount,
          delivery: { type: null }
        }]
      }
    }
  },
  actions: {
    checkout({dispatch}, product) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('api/v2/frontend/contract', product).then((response) => {
          resolve({data:true})
        }).catch((error) => {
          reject(error.response)
        })
      })
    },
    SetCart({  getters, dispatch, rootGetters, commit }, data){ 
      if(rootGetters['Account/isAuthenticated']){
        return new Promise((resolve, reject) => {
          if (!getters.hasProduct(data.id)){ 
            let productData={ 
              products:[
              {
                num:getters.productsCount+1,
                type:'basket',
                product_id:data.product_id ? data.product_id :data.id
              }
            ]}
            Vue.axios.post("api/v1/buyer/products?type=basket", productData)
              .then((res) => { 
                dispatch('GetCart')
                resolve(res.data); 
              })
              .catch((error) => {
                reject(error.response);
              });
          }
          else{
            let productData={
              type:'basket',
              product_id:data.product_id ? data.product_id :data.id
            } 
            Vue.axios.put("api/v1/buyer/products", productData)
            .then((res) => {  
              dispatch('GetCart')
              .finally(()=>{
                resolve(res.data); 
              })
              
            })
            .catch((error) => { 
              reject(error.response);
            });  
          }
        });
      }
      else{
         
        commit('TOGGLE', data)
      }
    },
    CheckCart({state, dispatch}){ 
      Vue.axios.get("api/v1/buyer/products?type=basket")
          .then((response) => { 
            let data={
              products:[]
            }
            if(state.cart.length){
              let index=response.data.data.length
              let i=false
            state.cart.forEach( productStore => { 
              i=false
              response.data.data.forEach(productBack=>{
                if(productBack.product_id==productStore.product_id){
                  i=true
                  index=index+1
                }
              })
              if(!i){
                data.products.push({
                  num:index,
                  type:'basket',
                  product_id:productStore.product_id ? productStore.product_id :productStore.id
                })
              }
              
            })  
            if(data.products.length){
              Vue.axios.post("api/v1/buyer/products?type=basket", data)
              .then(() => { 
                dispatch('GetCart') 
              }) 
            } 
            } 
            else{
              dispatch('GetCart') 
            }
          })  
    },
    GetCart({commit, rootGetters, state}){
      if(rootGetters['Account/isAuthenticated']){
        return new Promise((resolve, reject) => { 
          Vue.axios.get("api/v1/buyer/products?type=basket")
            .then((response) => {
              let price = 0;
              let list=[]
              response.data.data.forEach((product, i) => { 
                if(state.cart[i]&&state.cart[i].amount && state.cart[i].amount > product.min_amount){
                  price += Math.round(parseFloat(state.cart[i].amount) * product.price)
                  list.push({...product, amount: state.cart[i].amount, delivery: { type: null }})
                }
                else{
                  price += Math.round(parseFloat(product.min_amount ? product.min_amount:1) * product.price)
                  list.push({...product, amount: product.min_amount? product.min_amount:1, delivery: { type: null }})
                }
                 
              })  
              commit("SET_CARTLIST",  list);  
              resolve(response);
            })
            .catch((error) => {
              reject(error.response.data);
            });
        });
    }
    }
  },
}