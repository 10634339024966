<template>
<div id="app" ref="mainApp">
    <data-preloader @loaded="loaded = true" />
    <router-view v-if="loaded" />
    <transition name="bounce">
        <button @click="scrollToUp" class="mover_up" v-if="isToppage">
            <p-icon icon="mdi mdi-arrow-up" class="mover_up-icon" />
        </button>
    </transition>
</div>
</template>

<script> 


// (function (d, w, c) {
//   w.CALL24_ID = "NloNszJRlBFe3oN0bDc0OtF9G8AMf7lz";

//   function as(u, a) {
//     let s = d.createElement("script");
//     s.async = a;
//     s.src = u;
//     if (d.head) d.head.appendChild(s);
//   }

//   function ac(u) {
//     let s = d.createElement("link");
//     s.href = u;
//     s.rel = "stylesheet";
//     if (d.head) d.head.appendChild(s);
//   }

//   as("https://dev-app.call24.uz/widget/config.js");
//   as("https://dev-app.call24.uz/widget/widget.umd.min.js");
//   ac("https://dev-app.call24.uz/widget/widget.css");
// })(document, window, "Call24");



import DataPreloader from "@/components/DataPreloader.vue";

export default {
    components: {
        DataPreloader
    },
    data() {
        return {
            loaded: false,
            isToppage: false,
        };
    },

    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },

    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    methods: {
        scrollToUp() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },

        handleScroll() {
            window.pageYOffset > 300 ? (this.isToppage = true) : (this.isToppage = false);
        },
    },

    created() {
        this.$store.commit("Categories/CLOSE_CATEGORIES_MENU");
        this.$store.dispatch("Wishlist/GetProduct").catch(()=>{})
        this.$store.dispatch("Cart/GetCart").catch(()=>{})
        // this.$store.dispatch('Account/setMoney').catch(()=>{})

        //  this.$store.dispatch("Cart/SetCart") 
    },
    watch: {
        $route() {
            this.$store.commit("Categories/CLOSE_CATEGORIES_MENU");
        },
    },
};
</script>

<style>
.__jivoMobileButton {
    bottom: 55px !important
}

._orientationRight_afd9 .button_e21c {
    margin-right: 15px !important;
}

.slick-slider {
    margin: 0 -10px;
}

.slick-slide {
    padding: 0px 10px !important;
    text-align: center;
}

#call24-wf {
    z-index: 300;
}

 
</style><style lang="scss" scoped>
@import "platon-ui/scss/utils/_variables.scss";

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}

.mover_up {
    position: fixed;
    bottom: 80px;
    right: 5%;
    z-index: 99;
    background-color: #fff;
    border: none;
    box-shadow: 0px 0px 43px 10px rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    width: 45px;
    height: 45px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 600px) {
        right: 7%;
    }

    &-icon {
        font-size: 23px;
        opacity: 0.6;
    }
}
.bot-web-view{
    position: fixed;
    width: 100vw;
    height: 100vh;
    padding: 10px;
    top: 0;
    left: 0;
    overflow: auto;
}
</style>
