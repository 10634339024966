export default {
  lang: "Qaraqalpaqsha",
  interface: {
    unknown_erorr: "Belgisiz qáte: {error}",
    error_occurred: "Qátelik júz berdi",
    error_message:
      "Operaciya dawamında qátelik júz berdi. Iltimas, usı máseleni sheshiw ushın texnik járdemge múrájat qılıń.",
    fill_required_fields: "(*) Belgilengen barlıq maydanlardi toltırıń",
    more: "Tolıǵıraq",
    hide: "Jıynaw",
    edit: "Ózgertiriw",
    choose: "Tańlaw",
    back: "Artqa",
    pagination_back:'Artqa',
    forward: "Aldınǵa",
    wishlist: "Tańlanǵanlar",
    remove: "Óshiriw",
    cancel: "Biykar qılıw",
    save: "Saqlaw",
    are_you_sure: "Isenimińiz kámilme?",
    understand: "Túsinerli",
    search: "Izlew",
    all_search_results: "Barlıq izlew nátiyjeleri",
    avarage_costs: "smart-market.uz sawda maydanındaǵı ortasha bahalar",
    seen_results: "Siz {total} nátiyjeden {viewedItems} kórdińiz",
    search_undefined:"Qıdırıw kriteryaına uyqas maǵlıwmat tabilǵan zatdı!",
    yes:"Awa",
    no:"Joq"
  },
  navigation: {
    seller_cabinet: "Shaxsiy kabinet",
    orders: "Buyurtpalar",
    my_orders: "Buyurtpalar",
    my_cards: "Kartalar",
    notifications: "Xabarnamalar",
    wishlist: "Tańlanǵanlar",
    cart: "Sebet",
    logout: "Shıǵıw",
    documents: "Hújjetler",
    contracts: "Shártnamalar",
    invoices: "Esap-fakturalar",
    login: "Kirish",
    main: "Bas bet",
    category: "Kategoriya",
    profile: "Profil",
    all_categories: "Barlıq túrler",
    search_placeholder: "Ónim atı yaki yoki id nomerin kiritiń.",
    search_button: "Izlew",
    delivery_to: "Siniń mánzilińiz <span>{place}</span>", //?
    choose_delivery: "Rayon/qalanı tańlań",
    choose_delivery_message:
      "Sizge eń jaqın hám mas keletuǵın ónim hám satıwshılardı kórsetiwimiz ushın siz jaylasqan rayon/qalanı tańlań.",
    help: "Telegram arqalı múrájat qılıw",
    old_version: "Eski versiyaǵa ótiw",
    back_to_main:'Tiykarǵı betke ótiw',
    all:'Barlıǵı',
    confirmed_products:'Tastıyıqlanǵan ónimler',
  },
  footer: {
    subscribe_text:
      "Elektronika, balalar hám úy ushın ónimler boyınsha shegirmelerden xabardar bolıń.",
    subscribe: "Aǵza bolıw",
    email_placeholder: "Elektron pochta",
    follow_us: "Bizdi sociallıq tarmaqlarda kórip barıń.",
    copyright: "© 2021-2023 — «Smart Marketplace» JShJ. Barlıq huquqlar qorǵalǵan.",
    report_problem: "Saytdan paydalanıwda máseleler barma ?",
    stir: "СТИР",
    mfo: "МФО",
    xr: "ҲP",
    branch:"MCHJ",
    goal_of_payment: "Tólew maqseti",
    offert: "Ǵalabalıq ofertaga tiykarınan girew aqshası kóshirildi",
    bank_branch: "Shayıqontoxur filialı",
    offert_key_title: "Ǵalabalıq oferta shárti tiykarında",
    offert_info_text: '4.5. Avans aqshası támiynatshınıń SHAXSIY esap - nomerinen pul kóshiriw jolı (ПEРEЧИСЛEНИE) menen " SMART MARKETPLASE" MCHJning esap betine kóshiriledi (kópshilik jaǵdaylarda sistema daǵı processler waqıtı ádetdegidan cho‘ziladi)',
  },
  routes: {
    home: "Bas bet",
    cart: "Sebet",
    login: "Sistemaǵa kiriw",
    register: "Dizimnen ótiw",
    orders: "Zakazlar",
    plasticCard: "Kartalar",
    wishlist: "Tańlanǵanlar",
    checkout: "Zakazdi rásmiylestiriw",
    products: "Ónimler",
    personalData: "Jeke maǵlıwmat",
    notifications: "Xabarnama",
    contracts: "Shartnoma",
    invoice: "Esap-faktura",
    compair:'Salıstırıwlaw ',
    cash_money:"Naqd pul",
    order_confirmation: "Zakazdi tasdiyqlaw",
  },
  plastic_card: {
    add_card_title: "Jańa karta qosıw",
    enter_card_title: "Jańa karta kiritiw",
    choose_card_view_title: "Karta kórinisin tańlań",
    choose_card_type_title: "Karta túrin tańlań",
    enter_card_number_title: "Karta nomerin kiritiń",
    expiry_date_title: "Jaraqlılıq múddeti",
    main_card_title: "Tiykarǵı karta",
    enter_card_name_title: "Karta atın kiritiń",
    save_btn_title: "Saqlaw",
    confirmSmsTitle: "SMS kodti tasdıyqlaw",
    enter_sms_code_title: "Telefon nomerińizge jiberilgen kodti kiritiń",
    not_confirmed_txt: "Tasdıyqlanbaǵan",
    please_confirm_txt: "Iltimas kartańizdi tasdıyqlań",
    confirm_txt: "Kartanı tasdıyqlaw",
    delete_modal_title: "Kartanı óshiriw",
    confirm_delete_card_title: "Dıqqat kartanı óshiriwdi tasdıyqlaysızba ?",
    delete_card_btn: "Óshiriw",
    exist_card_txt: "Bul karta bazada bar!",
    wrong_code_txt: "Kiritilgen kod nadurıs",
    retry_code_txt: "Kodtı qayta jiberiw",
    not_available: "Joq"
  },
  login: {
    wrong_credentials: "Kiritilgen maǵlıwmatlardıń tuwrılıǵın tekseriń",
    fill_all_inputs: "Barlıq maydanlardi toltırıń",
    not_registred: "Siz dizimnen ótpegensiz",
    button_text: "Kiriw",
    esign: "ECI arqalı kiriw",
    choose_key: "Dizimnen giltti tańlań",
    date: "Sáne",
    auth_required: "Sistemaǵa kiriw talap qılınadı",
    auth_required_message: "Dawam etiw ushın sistemaǵa kiriwińiz kerek.",
    auth_action_text: "Sistemaǵa kiriw",
    type:'Sistemaǵa kirisiw ushın ózińiz ushın maqul usıldı saylań',
    auth_application:'Arza nomeri arqalı kirisiw',
    checking:"Maǵlıwmatlar tekserilip atır",
    one_id:"One Id arqalı kirisiw",
    error:"Sistemaǵa kirisiwde qátelik júz berdi!",
    login:"Login",
    password:"Parol",
    error_login_password:"Login yamasa parol qáte!"
  },
  esign: {
    keys_not_found: 'Elektron giltler tabılmadı',
    client_not_found: 'E-IMZO-nı jalǵawda qátelik juz berdi. Brawzer yamasa E-IMZO modulin ornatıń.',
    wrong_password: 'Parolińiz tuwrı yamasa joqlıǵın tekseriń',
    eimzo_site: 'E-IMZO veb-saytı',
    tin: 'STIN',
    pinfl: 'FShJIN',
    cert_expired: 'Múddeti ótken',
    cert_active: 'Aktiv',
    cert_type_entity: 'Fizikalıq shahs',
    cert_type_individual: 'Yuridikalıq shaxs',
  },
  register: {
    passwords_mismatch: 'Kiritilgen paroller sáykes kelmedi',
    incorrect_phonenumber: 'Tuwrı telefon nomerin kiritiń'
  },
  orderStates: {
    CONTRACT_CREATED: "Shártnama tayarlandı",
    CONTRACT_IN_PROCESS: "Shártnamanı jetkerip beriwshi qol qoydı",
    CONTRACT_CANCELED: "Shártnama dúziw biykar qılındı",
    CONTRACT_REJECTED: "Shártnamaǵa qol qoyıw biykar etildi",
    CONTRACT_FULL_SIGNED: "Shártnamaǵa tolıq qol qoyıldı",
  },
  delivery_types: {
    bring: "Satıwshınıń skladınan alıp ketiw",
    delivery: "Kurer arqalı jetkizip beriw",
    choose: "Jetkizip beriw usulın tańlań",
  },
  delivery_types_short: {
    bring: "Alıp ketiw",
    delivery: "Jetkizip beriw",
  },
  product: {
    card_delivery: 'Jetkizip beriw',
    add_to_cart: 'Sebetke qosıw',
    already_in_cart: 'Sebetke qosıldı',
    added_to_cart: 'Ónim sebetke qosıldı',
    added_to_cart_message: 'Siz zakazdi rásmiylestiriw ushın házir yaki sawdańızdı jumaqlaǵannan keyin sebetge kiriwińiz mumkin.',
    continue_shopping: 'Sawdalardı dawam ettiriw',
    move_to_cart: 'Sebetke ótiw',
    delivery_days: 'Jetkizip beriw zakaz tasdıyqlanǵannan baslap {days} kún ishinde satıwshı tárepinen ámelge asırıladı',
    delivery_type: 'Jetkerip beriw usılı',
    free: 'Biypul',
    choose_delivery_type: 'Jetkerip beriw usılın tańlań',
    delivery_select_label: 'Sizge ónimdi qanday kóriniste alǵan qolay?',
    delivery_address: 'Jetkizip beriw mánzili',
    bring_select_label: 'Qabıl qiliw máskánin tańlań',
    short_info: 'Ónim haqqında qısqasha',
    unit: 'Ólshew birligi',
    min_amount: 'Eń kem muǵdarı',
    expiration_life: 'Ámel qiliw múddeti',
    free_service_life: 'Biypul servis xızmeti',
    ayear: 'Islep shıǵarılǵan jıl',
    country_name: 'Islep shıǵarıwshı mámleket',
    make_name: 'Islep shıǵarıwshı',
    description: 'Kórinisi',
    delivery_unaviable: 'Siziń aymaǵıńızda joq',
    bring_addresses: '{count} shıǵarıw máskánı (leri)',
    id_number: 'ID nomer',
    same_products: 'Uqsas ónimler',
    detail_info: 'Keńirek maǵlıwmat',
    comment: 'Kommentariya',
    leave_feedback: 'Kommentariya qaldırıw',
    leave_comment_about_product_title: 'Ónim haqqında pikir qaldırıw',
    leave_comment_about_product: ' Ónim haqqında pikir qaldırıń *',
    you_commented: 'Siz usı ónimge kommentariya qaldırdıńız!',
    you_need_buy_product: 'Pikir qaldırıw ushın ónim satıp alıwıńız kerek!',
    you_need_login: 'Kommentariya qaldırıw ushın sistemaǵa kiriwińiz kerek! ',
    good_product: "Jaqsı ónim ",
    bad_product: "Qanaatlandırmawshı ónim ",
    comment_txt: "Kommentariya",
    good_txt: "Jaqsı",
    bad_txt: "Jaman",
    detail_not_found: "Ónim tabilǵan zatdı",
    asaxiy_products: 'Asaxiy ónimleri',
    new_product:'Jańa',
    base_amount:"Bar-yo'g'i {count} {unit} qaldı ",
    week_sold_amount:'Bul háptede {count} kisi satıp aldı ',
    week_sold_first:"Bul háptede birinshi bolıp satıp alın ",
    market_adresses:"Dúkan mánzilleri",
    market_adresses_title:"Ónim tómendegi dúkanlarımızda ámeldegi",
    available:"Ámeldegi",
    buy_one:"Bir klikda satıp alıw ",
    buy_one_buy:"Satıp alıw ",
    shipping_type:'Qabıllaw usılları',
    payment_type:"Tólew usılları ",
    copy_data:"Malumot kóshirildi",
    share:"Jalǵawısh"
  },
  seller: {
    reviews: "{count} oy-órisler",
    show_phonenumber: "Telefon nomerin kóriw",
  },
  address: {
    new: 'Jańa adres',
    region: 'Aymaq',
    district: 'Rayon',
    address_input_label: 'Mánzildi kórsetiń',
    comment_label: 'Kommentariya'
  },
  wishlist: {
    empty_text: 'Ónim betinde ♡ tuymesin basıń hám ózińizge jaqqan ónimdi usı jerge qosıń.',
    empty_title:"Saylanǵanlar dizimi bos ",
    empty_message:"Maqul kelgen ónimlerdi keyin kóriw yamasa harid qılıw ushın saylanǵanlar dizimine kiritiń."
  },
  confirmed:{
    empty_title:"Tastıyıqlanǵan ónimler joq!",
    empty_text:"Tastıyıqlanǵan ónimler joq! Olar tastıyıqlanǵannan keyin bul aynada kórinedi."
  },
  orders: {
    empty_text: "Zakazlarıńız joq.",
    order_proccess: "Zakaz № {document_id} processi",
    performed: "Orinlandi",
    not_performed: "Orinlanbadi",
    in_progress: "Processte",
    pending: "Kutilmekte",
    make_order: "Zakaz qiliw",
    order_confirmation: "Zakazdi tasdiyqlaw",
    order_delivery: "Zakazdi jetkiziw",
    order_finish: "Zakazdi jumaqlaw",
    unpassed_stage: "Ótilmegen basqish",
    orders_story: "Zakazlar tariyxi",
    product_txt: "Ónim",
    amount: "Muǵdari",
    order_rejected: "Niykar qilinǵan",
    order_delivered: "Juwmaqlanǵan",
    search_input_placeholder: "Ónim atınıń kiritiń",
    confirm:'Tastıyıqlaw',
    send_confirm_code:"Tastıyıqlaw kodı sizdiń telefoninggizga sms tarizda jiberildi!",
    re_send:"Qayta jiberiw"

  },
  cart: {
    empty_text: "Siziń sebetińiz bos.",
    total: "Jámi",
    products_count: "Ónim, {count} dana",
    delivery: "Jetkerip beriw",
    remove_confirm_text: "Haqıyqatdan da bul ónimdi sebetden alıp taslamaqshısızba?",
    checkout_button_text: "Zakazlardı rásmiylestiriw",
    choose_org:"Buyırtpanı suwretilestiriw ushın kárxananı saylań ",
    org:"Shólkemler",
    empty_title:"Sebetchada házirde hechnima joq",
    empty_message:"Maqul kelgen ónimlerdi satıp alıw ushın sebetshege áskerg."
  },
  category: {
    products_count: '{count} ónim(ler)',
    empty_text: 'Izlew miyzanıńızǵa sáykes ónim tabılmadı.'
  },
  checkout: {
    delivery_unaviable: 'Usı jóneliste tovarlardı jetkizip beriw ámelge asırılmaydı',
    payment_type: 'Tólem usılı',
    pay_button: 'Zakaz ushın pul tólew',
    choose_payment_type_txt: "Saylań",
    family_credit:"Shańaraq kredit",
    choose_delivery_type: 'Jetkerip beriw usılın tańlań',
    choose_delivery_type_message: 'Asıqpań, aldın siz ózińiz ushın qolay jetkerip beriw usılın tańlawıńız kerek',
    insufficient_funds: 'Jeterli qárejet joq',
    insufficient_funds_message: 'Rásmiylestirilgen kredit summası alanıp atırǵan tovar yaki xızmet ushın jeterli emes',
    force_pay: 'Óz qarjımnan parqın tólew',
    attention_not_enough_money:"Dıqqat buyırtpanı rásmiylestiriw ushın esabıńızde jetkilikli aqsha joq!",
    hight_price:"Kiritilgen pul muǵdarı buyırtpa bahasınan bálent!",
    enter_family_credit:"Shańaraqkredit arza shártnamasınan ajıratılǵan kredit muǵdarınan tısqarı qosımsha tólew túrleri kirgizildi",
    additional_payment:"Qosımsha tólew túrin saylań:",
    accept_application:"Sizdig arzańız qabıllandı",
    pay_cash_question:"Siz bul buyırtpa ushın tólewdi naq pulda ámelge asırmoqchimisiz?",
    accaunt_isnot_full:`Buyırtpanı rásmiylestiriwde dawam ettiriw ushın <a href="{link}" class="link">shaxsiy maǵlıwmatlaringizni</a> to'ldirishingiz kerek.`
  },
  payment_types: {
    credit: 'Oilakredit kreditini rásmiylestiriw',
    cash: 'Naq pul arqalı'
  },
  ocredit: {
    aplications_empty: `Sizde ámeldegi shártnamalar joq, dawam ettiriw ushın <a class="link" href="{link}" target="_blank">shártnamanı rásmiylestriwińiz</a> kerek.`,
    login: 'Sistemaǵa kiriw',
    login_message: 'Kredit alıw arzańızdaǵı maǵlıwmatlardı kiritiń',
    login_info: 'Arza nomeri hám sánesin kiritiń.',
    auth_error: 'Kredit shártnama qol qoyılmaǵan'
  },
  home: {
    banner0:{
      title1:'Jańa jıl - bul jańa biznes',
      title2:'<span>Jańa </span> jılıńız menen',
      text:"Jańa jılda óz shańaraqqa tiyisli biznesińizge ıyelewdi qáleysizbe ol jaǵdayda SMART-MARKET. UZ bul ushın eń jaqsı tańlaw."
    },
    banner1:{
      title1:'ENDI ASAXIY ÓNIMLERI',
      title2:'<span>SMART-MARKET.UZ</span> DE',
      text:'Qálegen túrdegi ASAXIY qosımshası ónimlerin SMART-MARKET.UZ arqalı satıp alınǵan zat etiń.'
    },
    banner2:{
      title1:"Óz biznesińizge iye bolıń",
      title2:'<span>SMART-MARKET.UZ</span> Siz menen',
      text:"Shańaraqqa tiyisli biznesińizge tez hám ańsat iye bolıń yamasa biznesińizdi biz menen rawajlantiring."
    },
    banner3:{
      title1:"Fermer bolıwchimisiz?",
      title2:'<span>Biz</span>  siz ushın háreket etemiz',
      text:"Jeke fermer xojalıǵıńız bolıwın qáleysizbe? Biz menen bul endi mashqala emes."
    },
    welcome: '<div class="big">Smart Marketga</div> <div>Xosh kelipsiz</div>',
    sponsor: 'programma tárepinen qollap-quwatlanadı',
    ocredit_login_button: 'Kredit alıw arzańız nomeri menen Sistemaǵa kiriń',
    are_you_seller: 'Ónim satasızba? ',
    start_selling: 'Bizde satıwdı baslań',
    more: 'hámmesi',
    ocredit_view_products: 'Kredit arza boyınsha ónimlerdi kóriń',
    telegram_message: 'Telegram kanalımızdaǵı saytdan paydalanıw boyınsha qollanbalar menen tanısıp shıǵıń.',
    telegram_action: 'Telegram kanalǵa ótiw',
    handbook: 'Video qollanbalar',
    main_slider_title: '<h1 class="main_slider_heading">Óz shańaraw <span>biznesińizdi</span> <br />Jaratıń</h1>',
    main_slider_asaxiy_title:
    '<h1 class="main_slider_heading">Endi <span>Asaxiy</span> Onimleri <br /> <span>SMART-MARKET.UZ de!</span></h1>',
    main_slider_description: "Óz idyalarıńızdı ómirge inám etiń ",
    continue_to_buy: "Sawdanı dawam ettiriw ushın sistemaǵa kiriń ",
    do_you_buy: "Ónim satasızba?",
    connect_to_system: "Sistemaǵa jalǵanıń",
    main_categories: "Baslı turler",
    average: "Ónim hám xızmetlerdiń ortasha baxasi",
    see_all: "Hámmesin kóriw ",
    high_products_title: "Tutunıwshılar tárepinen joqarı bahalanǵan ónim hám xızmetler",
    best_products_title:"Eń kóp sotilgan ónimler",
    cheap_products_title: "Eń arzan ónimler (jónelisler boyınsha)",
    new_products_title: "Jańa qosılǵan ónimler ",
    bannerTitle: "MOBIL BAǴDARLAMADA SÚYIKLI ÓNIMIŃIZDI SATÍP ALÍŃ, HÁM 20% ShEKEM TEJEP QALÍŃ!",
    qrCodeTitle: "Júklep alıw ushın kamerańızdı QR kodqa qaratıń ",
    detailedTitle: "Tolıǵıraq",
    simple_with_us:"Biz menen barlıǵı júdá tez hám ańsatlaw!",
    wait_you:"Eń arzan bahalar sizdi kútip atır!"
  },
  banners:{
    with_us:"Biz menen birge!"
  },
  sorting_types: {
    popular: 'ataqlılıǵı boyınsha',
    price: 'Baxasi boyınsha',
    rating: 'reytińi boyınsha',
    review: 'kommentariyalar boyınsha ',
    discount: 'shegirmeler boyınsha ',
    new: 'jańalıǵı boyınsha ',
  },
  subscribe: {
    title: 'Aǵza bolǵanıńız ushın raxmet',
    description: 'Eń sońǵı shegirmeler hám jańalıqlar ushın pochta qutıńızdi tekseriń.'
  },
  issue_report: {
    title: 'Másele haqqında xabar beriw',
    description: 'Máseleńizdi iláji barınsha tolıǵıraq kórsetiń',
    name: 'Atıńız?',
    phonenumber: 'Telefon nomerińiz',
    describe_problem: 'Máseleńizdi súwretlep beriń',
    send: 'Jiberiw',
    success: 'Siziń arzańız sátli jiberildi!',
    wait_until_call: 'Biz álleqashan sorawıńızdı kórip shıqpaqtamız, iltimas, operatorımız qońırawın kwtiń.'
  },
  filters: {
    category: 'Túri',
    price: 'Baxasi',
    from: 'dan, swm',
    to: 'shekem, swm',
    available_for_credit: 'Kreditke bar',
    credit_tip: '«Oila kredit» baǵdarlaması menen satıp alıw',
    delivery_types: 'Jetkerip beriw túri',
    delivery: 'Jetkezip beretuǵın arqalı',
    bring: 'Satıp alıwshı alıp ketedi',
    by_contracts: 'Arzadaǵı ónimlerdi kórsetiw',
    all_regions: 'Hámme aymaqlar boyınsha',
    region: 'Aymaq',
    see_more_txt: "Kóbirek kórsetiw",
    see_less_txt: "Kemirek kórsetiw",
    additional_filters: "Qosımsha filtrler",
    seller_txt: "Támiynatshılar",
    clear_filters: "Filtrdi tazalaw",
    providers_not_found: "Qıdırıw kriteryaıńızǵa uyqas támiynatshılar tabilǵan zatdı",
    sort:"Tártiplew",
    filter:'Filtrler',
    show:"Kóriw",
    clear:'Tazalaw'
  },
  personal_data: {
    phone_number: "Telefon nomeri",
    backup_phone_number: "Qosimsha telefon nomeri",
    telegramId: "Telegram ID",
    email_box: "Elektron poshta",
    save_btn: "Ózgerislerdi saqlaw",
    email_placeholder: "Poshtanizdi kiritiń",
    message_type: "Xabarnama jiberiw usili",
    sms: "SMS",
    telegram_bot: "Telegram bot",
    email: "Email",
    get_id_info: "ID aliw ushin usi túymege basiń",
    error_photo_title: "Iltimas súwret kiritin",
    error_message_type: "Iltimas Xabarnama keliw usilin tanlan",
    photo_validation_text: "Iltimas kolemi 2 MB tan kem bolǵan súwret kiritin",
    id_validation: "ID aliw ushin telefon nomer maydanin kiritiń!",
    id_not_found: "ID tabilmadi",
    email_validation_text: "Iltimas email manzilin tuwri kiritiń!",
    success_send: "Maǵliwmatlar sátli saqlandi!",
    message_connect_tg_bot: `<span>Bot arqalı xabarnoma alıw ushın Botga jalǵanıwıńız kerek.<a class="pui-text is-primary" href="https://t.me/smart_market_rs_bot" target="_blank"> Botga ótiw</a></span>`,
    input_tg_bot: `<span>ID alıw ushın Bot ga jalǵanıwıńız kerek.<a class="pui-text is-primary" href="https://t.me/smart_market_rs_bot" target="_blank"> Botga ótiw</a></span>`,
    add_new_adress: "+ Jańa mánzil qosıw",
    delivery_addresses: "Jetkiziw aymaqların kiritiń",
  },
  documents: {
    empty_contracts: "Shártnamalar joq",
    provider: "Jekizip beriwshi",
    contract_txt: "Shártnama",
    signed_day: "Qol qoyilǵan sáne",
    state: "Jaǵdayi",
    count_txt: "Nómer",
    date_txt: "Sáne",
    sum_txt: "Summa",
    seller_txt: "Swtiwshi",
    buyer_txt: "Satip aliwshi",
    rejected: "Biykar etildi",
    view: "Kórip shiǵilmaqta",
    created: "Qabil qilindi",
    in_process: "Qol qoyiw processinde",
    in_process_confirmation:"Tastıyıqlshada",
    canceled: "Biykar qilindi",
    rejected: "Biykar etildi",
    full_signed: "Toliq qol qoyildi",
    sys_canceled: "Sistema biykarladi",
    cancel_view: "Biykar qiliw",
    contract_is_canceled:"Sizdiń shártnamańız biykar etildi!",
    contract_is_canceled_message:"Sizdiń shártnamańız biykar etildi, istalagan waqıtta biz siz menen jańa shártnama dúziwine tayınmız.",
    cancel_contract:"Shártnamanı bıykarlaw ",
    cancel_contract_right:"Siz bul shártnamanı ne ushın bıykarlawchisiz?",
    cancel_contract_reason:"Shártnamanı bıykarlaw sebebi",
    search_by_number_placeholder: "Shártnama nomer",
    seacrch_by_number_name:"Ónim atı yamasa shártnama nomer",
    canceled_date:'Biykar etilgen sáne',
    payment:"Tólew",
    cash_money:"Naq pul",
    total_cash:"Jámi summa ",
    confirm_orers:"Sizdiń buyırtpańız tabıslı rásmiylestirildi!",
    confirm_orers_text:"Sizdiń buyırtpańız rásmiylestirildi naq pul bóliminden buyırtpa chekin kóriwińiz múmkin!",
    order_is_canceled:"Sizdiń buyırtpańız biykar etildi!",
    order_is_canceled_message:"Sizdiń buyırtpańız biykar etildi, qálegen waqıtta qayta buyırtpa beriwińiz múmkin.",
    cancel_order:"Buyırtpanı bıykarlaw ", 
    cancel_order_right:"Siz bul buyırtpanı ne ushın bıykarlawchisiz?",
    cancel_order_reason:"Buyırtpanı bıykarlaw sebebi",
  },
  invoices: {
    empty_invoices: "Fakturalar joq",
    corporation_txt: "Kárxana",
    customer: "Klient",
    helper_hokim: "Hákim járdemshisi aktı",
    result: "Nátiyje",
    search_by_number_placeholder: "Faktura nomerin kiritiń",
  },
  notifications: {
    empty_notification: "Jańa xabarnamalar joq",
    empty_reads_notification: "Oqilǵan xabarnamalar joq",
    newest: "Jańa kelgenler",
    olders: "Aldinǵilari",
  },
  tariffs:{
    name:"Tariflar",
    price:'Xızmet bahası:',
    info:'Xızmet haqqında:',
    SILVER:{
      price:'Qarıydar hám Támiynatshı ortasındaǵı oferta summasınıń 1 procenti muǵdarın quraydı lekin bul summa BHMning 1 procentinen kem bolıwı múmkin emes.',
      info:'Bul tarifda Támiynatshıǵa usınısların qabıllaw, moderatsiyadan ótkeriw, tovarlar hám yoki xızmetler maǵlıwmatlar bazasına kirgiziw), tovarlar va/yoki xızmetlerdi realizatsiya qılıw (jetkiziw boyınsha shártnamalar joybarları, esap -fakturalar hám de tiyisli xabarnomalarni programmalıq tárzde tayarlaw ), jetkezip berilgen tovarlar va/yoki xızmetler ushın esap -kitaptı ámelge asırıw (naqd/naqd pulsiz, bank kreditleri jardeminde), realizatsiya nátiyjelerin telek esabında sáwlelendiriw hám de talaptı úyreniw (ayına 30 ta talap ), Támiynatshılar TOP dizimine jaylastırıw (ayına 5 kún) boyınsha qosımsha xızmetler kórsetiledi.'
    },
    GOLD:{
      price:'Qarıydar hám Támiynatshı ortasındaǵı oferta summasınıń 2 procenti muǵdarın quraydı lekin bul summa BHMning 1 procentinen kem bolıwı múmkin emes.',
      info:'Bul tarifda Támiynatshıǵa usınısların qabıllaw, moderatsiyadan ótkeriw, tovarlar hám /yoki xızmetler maǵlıwmatlar bazasına kirgiziw), tovarlar va/yoki xızmetlerdi realizatsiya qılıw (jetkiziw boyınsha shártnamalar joybarları, esap -fakturalar hám de tiyisli xabarnomalarni programmalıq tárzde tayarlaw ), jetkezip berilgen tovarlar va/yoki xızmetler ushın esap -kitaptı ámelge asırıw (naqd/naqd pulsiz, bank kreditleri jardeminde), realizatsiya nátiyjelerin telek esabında sáwlelendiriw hám de talaptı úyreniw (ayına 60 ta talap ), usınıslardı Qarıydarlarǵa birinshi náwbette jibesh (ayına 60 ta usınıs ), moderatsiyadan gezeksiz ótkeriw (ayına 10 ret), Támiynatshılar TOP dizimine jaylastırıw (ayına 10 kún) boyınsha qosımsha xızmetler kórsetiledi.'
    },
    PLATINUM:{
      price:'Qarıydar hám Támiynatshı ortasındaǵı oferta summasınıń 3 procenti muǵdarın quraydı lekin bul summa BHMning 1 procentinen kem bolıwı múmkin emes.',
      info:'Bul tarifda Támiynatshıǵa usınısların qabıllaw, moderatsiyadan ótkeriw, tovarlar hám /yoki xızmetler maǵlıwmatlar bazasına kirgiziw), tovarlar va/yoki xızmetlerdi realizatsiya qılıw (jetkiziw boyınsha shártnamalar joybarları, esap -fakturalar hám de tiyisli xabarnomalarni programmalıq tárzde tayarlaw ), jetkezip berilgen tovarlar va/yoki xızmetler ushın esap -kitaptı ámelge asırıw (naqd/naqd pulsiz, bank kreditleri jardeminde), realizatsiya nátiyjelerin telek esabında sáwlelendiriw hám de talaptı úyreniw (sheksiz), usınıslardı Qarıydarlarǵa birinshi náwbette jibesh (sheksiz), moderatsiyadan gezeksiz ótkeriw (sheksiz), texnikalıq járdemge gezeksiz baylanısıw, buxgalteriya esabın sistema menen integraciyalastırıw (bólek tólew ornına ), tavar hám xızmetler reklaması (ayına Atqarıwshınıń veb -betsinde 2 ret, Telegram kanalında 1 ret), Támiynatshılar TOP dizimine jaylastırıw (turaqlı ) boyınsha qosımsha xızmetler kórsetiledi.'
    }
  }
};
