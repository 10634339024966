import createPersistedState from "vuex-persistedstate";

const storage = window.localStorage
const storageKey = "SMART_MARKETPLACE_STORAGE"
const versionKey = "SMART_MARKETPLACE_STORE_VERSION"
const currentVersion = "1.3"

export default createPersistedState({
  key: storageKey,
  storage: {
    getItem() {
      if (storage.getItem(versionKey) !== currentVersion) {
        // console.log('updating store, version', currentVersion)
        storage.setItem(versionKey, currentVersion)
        return null
      } else {
        // console.log('restoring store')
        return storage.getItem(storageKey)
      }
    },
    setItem: (key, value) => storage.setItem(key, value),
    removeItem: (key) => storage.removeItem(key)
  },
})