export default {
  lang: "O'zbekcha",
  interface: {
    unknown_erorr: "Noma'lum xato: {error}",
    error_occurred: "Xatolik yuz berdi",
    error_message: "Operatsiya davomida xatolik yuz berdi. Iltimos, ushbu muammoni hal qilish uchun texnik yordamga murojaat qiling.",
    fill_required_fields: "(*) Belgilangan barcha maydonlarni to'ldiring",
    more: "Batafsil",
    hide: "Yig'ish",
    edit: "O'zgartirish",
    choose: "Tanlash",
    back: "Orqaga",
    pagination_back:'Orqaga',
    forward: "Oldinga",
    wishlist: "Tanlanganlar",
    remove: "O'chirish",
    cancel: "Bekor qilish",
    save: "Saqlash",
    are_you_sure: "Ishonchingiz komilmi?",
    understand: "Tushunarli",
    search: "Qidirish",
    all_search_results: "Barcha qidiruv natijalari",
    avarage_costs: "Smart-market.uz savdo maydonidagi o'rtacha narxlar",
    seen_results: "Siz {total} ta natijadan {viewedItems} tasini koʻrdingiz",
    search_undefined:"Qidiruv mezoniga mos maʼlumot topilmadi!",
    yes:"Ha",
    no:"Yo'q"
  },
  navigation: {
    seller_cabinet: "Shaxsiy kabinet",
    orders: "Buyurtmalar",
    my_orders: "Buyurtmalar",
    my_cards: "Kartalar",
    notifications: "Xabarnomalar",
    wishlist: "Tanlanganlar",
    cart: "Savat",
    logout: "Chiqish",
    documents: "Hujjatlar",
    contracts: "Shartnomalar",
    invoices: "Hisob-fakturalar",
    login: "Kirish",
    main: "Bosh sahifa",
    category: "Kategoriya",
    profile: "Profil",
    all_categories: "Barcha toifalar",
    search_placeholder: "Mahsulot nomi yoki id raqamini kiriting",
    search_button: "Qidirish",
    delivery_to: "Sizning manzilingiz <span>{place}</span>", //?
    choose_delivery: "Tuman/shaharni tanlang",
    choose_delivery_message:
      "Sizga eng yaqin va eng mos keladigan mahsulot va sotuvchilarni ko'rsatishimiz uchun siz joylashgan tuman/shaharni tanlang.",
    help: "Telegram orqali murojaat qilish",
    old_version: "Eski versiyaga o'tish",
    back_to_main:'Asosiy sahifaga o‘tish',
    all:'Barchasi',
    confirmed_products:'Tayyor maxsulotlar',
  },
  footer: {
    subscribe_text:
      "Elektronika, bolalar va uy uchun mahsulotlar bo'yicha chegirmalardan xabardor bo'ling",
    subscribe: "Obuna bo'lish",
    email_placeholder: "Elektron pochta",
    follow_us: "Bizni ijtimoiy tarmoqlarda kuzatib boring",
    copyright: "© 2021-2023 — «Smart Marketplace» MChJ. Barcha huquqlar himoyalangan.",
    report_problem: "Saytdan foydalanishda muammolar bormi?",
    stir: "STIR",
    mfo: "MFO",
    xr: "XR",
    branch:"MCHJ",
    goal_of_payment: "To‘lov maqsadi",
    offert: "Ommaviy ofertaga asosan garov puli ko‘chirildi",
    bank_branch: "Shayxontohur filiali",
    offert_key_title: "Ommaviy oferta sharti asosida",
    offert_info_text: '4.5. Zakalat puli taʼminotchining SHAXSIY hisob - raqamidan pul ko‘chirish yo‘li (ПEРEЧИСЛEНИE) bilan "SMART MARKETPLASE" MCHJning hisob raqamiga ko‘chiriladi (aksariyat holatlarda tizimdagi jarayonlar vaqti odatdagidan cho‘ziladi)',
  },
  routes: {
    home: "Bosh sahifa",
    cart: "Savat",
    login: "Tizimga kirish",
    register: "Ro'yxatdan o'tish",
    orders: "Buyurtmalar",
    plasticCard: "Kartalar",
    wishlist: "Tanlanganlar",
    checkout: "Buyurtmani rasmiylashtirish",
    products: "Mahsulotlar",
    personalData: "Shaxsiy ma’lumot",
    notifications: "Xabarnoma",
    contracts: "Shartnoma",
    invoice: "Hisob-faktura",
    compair:'Taqqoslash',
    cash_money:"Naqd pul",
    order_confirmation: "Buyurtmani tasdiqlash",
  },
  plastic_card: {
    add_card_title: "Yangi karta qo’shish",
    enter_card_title: "Yangi karta kiritish",
    choose_card_view_title: "Karta ko’rinishini tanlang",
    choose_card_type_title: "Karta turini tanlang",
    enter_card_number_title: "Karta raqamini kiriting",
    expiry_date_title: "Yaroqlilik muddati",
    main_card_title: "Asosiy karta",
    enter_card_name_title: "Karta nomini kiriting",
    save_btn_title: "Saqlash",
    confirmSmsTitle: "Sms kodni tasdiqlash",
    enter_sms_code_title: "Telefon raqamingizga yuborilgan kodni kiriting",
    not_confirmed_txt: "Tasdiqlanmagan",
    please_confirm_txt: "Iltimos kartangizni tasdiqlang",
    confirm_txt: "Kartani tasdiqlash",
    delete_modal_title: "Kartani o’chirish",
    confirm_delete_card_title: "Diqqat kartani o’chirishni tasdiqlaysizmi?",
    delete_card_btn: "O'chirish",
    exist_card_txt: "Bu karta bazada bor!",
    wrong_code_txt: "Kiritilgan kod noto'g'ri!",
    retry_code_txt: "Kodni qayta yuborish",
    not_available: "Mavjud emas"
  },
  login: {
    wrong_credentials: "Kiritilgan ma'lumotlarning to'g'riligini tekshiring",
    fill_all_inputs: "Barcha maydonlarni to'ldiring",
    not_registred: "Siz ro'yxatdan o'tmagansiz",
    button_text: "Kirish",
    esign: "ERI orqali kirish",
    choose_key: "Ro'yxatdan kalitni tanlang",
    date: "Sana",
    auth_required: "Tizimga kirish talab qilinadi",
    auth_required_message: "Davom etish uchun tizimga kirishingiz kerak.",
    auth_action_text: "Tizimga kirish",
    type:'Tizimga kirish uchun o‘zingiz uchun maʼqul usulni tanlang',
    auth_application:'Ariza raqami orqali kirish',
    checking:"Ma'lumotlar tekshirilmoqda",
    one_id:"One Id orqali kirish",
    error:"Tizimga kirishda xatolik yuz berdi!",
    login:"Login",
    password:"Parol",
    error_login_password:"Login yoki parol xato!"
  },
  esign: {
    keys_not_found: "Elektron kalitlar topilmadi",
    client_not_found:
      "E-IMZO-ni ulashda xatolik yuz berdi. Brauzer yoki E-IMZO modulini o'rnating.",
    wrong_password: "Parolingiz to'g'ri yoki yo'qligini tekshiring",
    eimzo_site: "E-IMZO veb-sayti",
    tin: "STIR",
    pinfl: "JSHSHIR",
    cert_expired: "Muddati o'tgan",
    cert_active: "Faol",
    cert_type_entity: "Yuridik shaxs",
    cert_type_individual: "Jismoniy shaxs",
  },
  register: {
    passwords_mismatch: "Kiritilgan parollar mos kelmadi",
    incorrect_phonenumber: "To'g'ri telefon raqamini kiriting",
  },
  orderStates: {
    CONTRACT_CREATED: "Shartnoma tayyorlandi",
    CONTRACT_IN_PROCESS: "Shartnomani ta'minotchi imzoladi",
    CONTRACT_CANCELED: "Shartnoma tuzish bekor qilindi",
    CONTRACT_REJECTED: "Shartnoma imzolash rad etildi",
    CONTRACT_FULL_SIGNED: "Shartnoma to'liq imzolandi",
  },
  delivery_types: {
    bring: "Sotuvchining omboridan olib ketish",
    delivery: "Kuryer orqali yetkazib berish",
    choose: "Yetkazib berish usulini tanlang",
  },
  delivery_types_short: {
    bring: "Olib ketish",
    delivery: "Yetkazib berish",
  },
  product: {
    card_delivery: "Yetkazib berish",
    add_to_cart: "Savatga",
    already_in_cart: " Savatga qo'shildi",
    added_to_cart: "Mahsulot savatga qo'shildi",
    added_to_cart_message:
      "Siz buyurtmani rasmiylashtirish uchun hoziroq yoki xaridlaringizni yakunlagandan keyin savatga kirishingiz mumkin.",
    continue_shopping: "Xaridlarni davom ettirish",
    move_to_cart: "Savatga o'tish",
    delivery_days:
      "Yetkazib berish buyurtma tasdiqlangandan boshlab {days} kun ichida sotuvchi tomonidan amalga oshiriladi",
    delivery_type: "Yetkazib berish usuli",
    free: "Bepul",
    choose_delivery_type: "Yetkazib berish usulini tanlang",
    delivery_select_label: "Sizga mahsulotni qanday tarzda olgan qulay?",
    delivery_address: "Yetkazib berish manzili",
    bring_select_label: "Qabul qilish maskani ",
    short_info: "Mahsulot haqida qisqacha",
    unit: "O'lchov birligi",
    min_amount: "Eng kam miqdori",
    expiration_life: "Amal qilish muddati",
    free_service_life: "Bepul servis xizmati",
    ayear: "Ishlab chiqarilgan yil",
    country_name: "Ishlab chiqaruvchi mamlakat",
    make_name: "Ishlab chiqaruvchi",
    description: "Tavsif",
    delivery_unaviable: "Sizning hududingizda mavjud emas",
    bring_addresses: "{count} chiqarish maskani(lari)",
    id_number: "ID raqam",
    same_products: "O'xshash mahsulotlar",
    detail_info: "Batafsil ma'lumot",
    comment: "Sharhlar",
    leave_feedback: "Izoh qoldirish",
    leave_comment_about_product_title: "Mahsulot haqida fikr qoldirish",
    leave_comment_about_product: "Mahsulot haqida fikr qoldiring *",
    you_commented: "Siz ushbu mahsulotga izoh qoldirgansiz!",
    you_need_buy_product: "Izoh qoldirish uchun mahsulot harid qilishiniz kerak!",
    you_need_login: "Izoh qoldirish uchun tizimga kirishingiz kerak! ",
    good_product: "Yaxshi mahsulot",
    bad_product: "Qoniqarsiz mahsulot",
    comment_txt: "Izoh",
    good_txt: "Yaxshi",
    bad_txt: "Yomon",
    detail_not_found: "Mahsulot topilmadi",
    asaxiy_products: "Asaxiy mahsulotlari",
    new_product:'Yangi',
    base_amount:"Bor-yo'g'i {count} {unit} qoldi",
    week_sold_amount:'Bu haftada {count} kishi sotib oldi',
    week_sold_first:"Bu haftada birinchi bo'lib sotib oling",
    market_adresses:"Do'kon manzillari",
    market_adresses_title:"Maxsulot quyidagi do'konlarimizda mavjud",
    available:"Mavjud",
    buy_one:"Bir klikda sotib olish",
    buy_one_buy:"Xarid qilish ",
    shipping_type:'Qabul qilish usullari',
    payment_type:"To‘lov usullari",
    copy_data:"Malumot ko'chirildi",
    share:"Ulashish"

  },
  seller: {
    reviews: "{count} fikr-mulohazalar",
    show_phonenumber: "Telefon raqamini ko'rish",
  },
  address: {
    new: "Yangi manzil",
    region: "Hudud",
    district: "Tuman",
    address_input_label: "Manzilni kiriting",
    comment_label: "Izoh",
  },
  wishlist: {
    empty_text:"Mahsulot sahifasida ♡ tugmasini bosing va o'zingizga yoqqan mahsulotni shu yerga qo'shing.",
    empty_title:"Tanlanganlar ro'yxati bo'sh",
    empty_message:"Ma'qul kelgan maxsulotlarni keyinroq ko‘rish yoki harid qilish uchun tanlanganlar ro‘yxatiga kiriting."
  },
  confirmed:{
    empty_title:"Tayyor maxsulotlar mavjud emas!",
    empty_text:"Tayyor maxsulotlar mavjud emas! Ular tasdiqlangandan so'ng ushbu oynada ko'rinadi."
  },
  orders: {
    empty_text: "Buyurtmalaringiz mavjud emas.",
    order_proccess: "Buyurtma № {document_id} jarayoni",
    performed: "Bajarildi",
    not_performed: "Bajarilmadi",
    in_progress: "Jarayonda",
    pending: "Kutilmoqda",
    make_order: "Buyurtma qilish",
    order_confirmation: "Buyurtmani tasdiqlash",
    order_delivery: "Buyurtmani yetkazish",
    order_finish: "Buyurtmani yakunlash",
    unpassed_stage: "O'tilmagan bosqich",
    orders_story: "Buyurtmalar tarixi",
    product_txt: "Mahsulot",
    amount: "Miqdori",
    order_rejected: "Bekor qilingan",
    order_delivered: "Yakunlangan",
    search_input_placeholder: "Mahsulot nomini kiriting",
    confirm:'Tasdiqlash',
    send_confirm_code:"Tasdiqlash kodi sizning telefoningizga sms tarzida yuborildi!",
    re_send:"Qayta yuborish"

  },
  cart: {
    empty_text: "Sizning savatingiz bo'sh.",
    total: "Jami",
    products_count: "Mahsulot, {count} dona",
    delivery: "Yetkazib berish",
    remove_confirm_text: "Haqiqatan ham bu mahsulotni savatdan olib tashlamoqchimisiz?",
    checkout_button_text: "Buyurtmani rasmiylashtirish",
    choose_org:"Buyurtmani rasmilashtirish uchun korxonani tanlang",
    org:"Tashkilotlar",
    empty_title:"Savatchada hozirda hechnima yo'q",
    empty_message:"Maqul kelgan maxsulotlarni xarid qilish uchun savatchaga qo'shing."
  },
  category: {
    products_count: "{count} mahsulotlar",
    empty_text: "Qidiruv mezoningizga mos mahsulotlar topilmadi.",
  },
  checkout: {
    delivery_unaviable: "Ushbu yo'nalishda tovarlarni etkazib berish amalga oshirilmaydi",
    payment_type: "To'lov usuli",
    family_credit:"Oila kredit",
    pay_button: "Buyurtma uchun pul to'lash",
    choose_payment_type_txt: "Tanlang",
    choose_delivery_type: "Yetkazib berish usulini tanlang",
    choose_delivery_type_message:
      "Shoshilmang, avval siz o'zingiz uchun qulay yetkazib berish usulini tanlashingiz kerak",
    insufficient_funds: "Yetarli mablag' mavjud emas",
    insufficient_funds_message:"Rasmiylashtirilgan kredit summasi olinayotgan tovar yoki xizmat uchun yetarli emas",
    force_pay: "O'z mablag'imdan farqini to'lash",
    attention_not_enough_money:"Diqqat buyurtmani rasmiylashtirish uchun hisobingizda yetarli mablag’ mavjud emas!",
    hight_price:"Kiritilgan pul miqdori buyurtma narxidan baland!",
    enter_family_credit:"Oilakredit ariza shartnomasidan ajratilgan kredit miqdoridan tashqari qo’shimcha to’lov turlari kiritildi",
    additional_payment:"Qo'shimcha to'lov turini tanlang:",
    accept_application:"Siznig arizangiz qabul qilindi",
    pay_cash_question:"Siz ushbu buyurtma uchun to‘lovni naqd pulda amalga oshirmoqchimisiz?",
    accaunt_isnot_full:`Buyurtmani rasmiylashtirishda  davom ettirish uchun <a href="{link}" class="link">shaxsiy ma'lumotlaringizni </a> to'ldirishingiz kerak.`
  },
  payment_types: {
    credit: "Oilakredit kreditini rasmiylashtirish",
    cash: "Naqd pul orqali",
  },
  ocredit: {
    aplications_empty: `Sizda amaldagi shartnomalar mavjud emas, davom ettirish uchun <a href="{link}" class="link" target="_blank">shartnomani rasmiylashtirishingiz</a> kerak.`,
    login: "Tizimga kirish",
    login_message: "Kredit olish uchun arizangizdagi ma'lumotlarni kiriting",
    login_info: "Ariza raqami va sanasini kiriting.",
    auth_error: "Kredit shartnoma imzolanmagan",
  },
  home: {
    banner0:{
      title1:'Tezkor va ishonchli',
      title2:'<span>savdo</span> tarafdorimisiz?',
      text:"Biz bilan barchasi oson va tez"
    },
    banner1:{
      title1:'ENDI ASAXIY MAHSULOTLARI',
      title2:'<span>SMART-MARKET.UZ</span> DA',
      text:'Istalgan turdagi asaxiy ilovasi mahsulotlarini smart-market.uz orqali xarid qiling.'
    },
    banner2:{
      title1:"O‘Z BIZNESINGIZGA EGA BO‘LING",
      title2:'<span>SMART-MARKET.UZ</span> SIZ BILAN',
      text:"Oilaviy biznesingizga tez va oson ega bo‘ling yoki biznesingizni biz bilan rivojlantiring."
    },
    banner3:{
      title1:"FERMER BO‘LMOQCHIMISIZ?",
      title2:'<span>BIZ</span> SIZ UCHUN HARAKAT QILAMIZ',
      text:"Shaxsiy fermer xo‘jaligingiz bo‘lishini xohlaysizmi? Biz bilan bu endi muammo emas."
    },
    welcome: '<div class="big">Smart Marketga</div> <div>Xush kelibsiz</div>',
    sponsor: "dastur tomonidan qo'llab-quvvatlanadi",
    ocredit_login_button: "Kredit olish uchun ariza raqamingiz bilan tizimga kiring",
    are_you_seller: "Mahsulot sotasizmi?",
    start_selling: "Bizda sotishni boshlang",
    more: "barchasi",
    ocredit_view_products: "Kredit ariza bo'yicha mahsulotlarni ko'rish",
    telegram_message:"Telegram kanalimizdagi saytdan foydalanish bo'yicha yo'riqnomalar bilan tanishib chiqing.",
    telegram_action: "Telegram kanalga o'tish",
    handbook: "Video yo'riqnomalar",
    main_slider_title:'<h1 class="main_slider_heading">O`z oilaviy <span>biznesingizni</span> <br />Yarating</h1>',
    main_slider_asaxiy_title:'<h1 class="main_slider_heading">Endi <span>Asaxiy</span> Mahsulotlari <br /> <span>SMART-MARKET.UZ da!</span></h1>',
    main_slider_description: "O'z g'oyalaringizni hayotga tatbiq eting",
    continue_to_buy: "Xaridni davom ettirish uchun tizimga <br />kiring",
    do_you_buy: "Mahsulot sotasizmi?",
    connect_to_system: "Tizimga ulaning",
    main_categories: "Bosh toifalar",
    average: "Mahsulot va xizmatlarning o‘rtacha narxi",
    see_all: "Barchasini ko'rish",
    high_products_title: "Iste’molchilar tomonidan yuqori baholangan mahsulot va xizmatlar",
    cheap_products_title: "Eng arzon mahsulotlar (yo’nalishlar bo’yicha)",
    new_products_title: "Yangi qo’shilgan mahsulotlar",
    best_products_title:"Eng ko‘p sotilgan mahsulotlar",
    bannerTitle: "MOBIL ILOVADA SEVIMLI MAHSULOTRAINGIZNI SOTIB OLING, VA 20% GACHA SAQLANG!",
    qrCodeTitle: "Yuklab olish uchun kamerangizni QR kodga qarating",
    detailedTitle: "Batafsil",
    simple_with_us:"BIZ BILAN BARCHASI JUDA TEZ VA OSONROQ!",
    wait_you:"ENG ARZON NARXLAR SIZNI KUTMOQDA!"

  },
  banners:{
    with_us:"Biz bilan birga!"
  },
  sorting_types: {
    popular: "mashhurligi bo'yicha",
    price: "narxi bo'yicha",
    rating: "reytingi bo'yicha",
    review: "sharhlar bo'yicha",
    discount: "chegirma bo'yicha",
    new: "yangiligi bo'yicha",
  },
  subscribe: {
    title: "Obuna bo'lganingiz uchun rahmat",
    description: "Eng so'nggi chegirmalar va yangiliklar uchun pochta qutingizni tekshiring.",
  },
  issue_report: {
    title: "Muammo haqida xabar berish",
    description: "Muammoingizni iloji boricha batafsil ko'rsating",
    name: "Ismingiz",
    phonenumber: "Telefon raqamingiz",
    describe_problem: "Muammoingizni tasvirlab bering",
    send: "Jo'natish ",
    success: "Sizning arizangiz muvaffaqiyatli yuborildi!",
    wait_until_call:
      "Biz allaqachon so'rovingizni ko'rib chiqmoqdamiz, iltimos, operatorimiz qo'ng'iroqlarini kuting.",
  },
  filters: {
    category: "Toifa",
    price: "Narxi",
    from: "dan, so'm",
    to: "gacha, so'm",
    available_for_credit: "Kredit mablag'idan to'lash imkino mavjud",
    credit_tip: "«Oila kredit» dasturi doirasida sotib olish",
    delivery_types: "Yetkazib berish turi",
    delivery: "Yetkazib beruvchi orqali",
    bring: "Sotib oluvchi olib ketadi",
    by_contracts: "Arizadagi mahsulotlarni ko'rsatish",
    all_regions: "Hamma hududlar bo'yicha",
    region: "Hudud",
    see_more_txt: "Ko'proq ko'rsatish",
    see_less_txt: "Kamroq ko'rsatish",
    additional_filters: "Qo‘shimcha filtrlar",
    seller_txt: "Taʼminotchilar",
    clear_filters: "Filtrni tozalash",
    providers_not_found: "Qidiruv mezoningizga mos ta'minotchilar topilmadi",
    sort:"Tartiblash",
    filter:'Filtrlar',
    show:"Ko'rish",
    clear:'Tozalash'
  },
  personal_data: {
    phone_number: "Telefon raqam",
    backup_phone_number: "Qo'shimcha telefon raqam",
    telegramId: "Telegram ID",
    email_box: "Elektron pochta",
    save_btn: "O'zgarishlarni saqlash",
    email_placeholder: "Pochtangizni kiriting",
    message_type: "Xabarnoma yuborish usuli",
    sms: "Sms",
    telegram_bot: "Telegram bot",
    email: "Email",
    get_id_info: "ID olish uchun ushbu tugmaga bosing",
    error_photo_title: "Iltimos rasm kiriting",
    error_message_type: "Iltimos Xabarnoma kelish usulini tanlang",
    photo_validation_text: "Iltimos hajmi 2 mb dan kam bo'lgan rasm kiriting",
    id_validation: "ID olish uchun telefon raqam maydonini to'ldiring!",
    id_not_found: "ID topilmadi!",
    email_validation_text: "Iltimos email manzilni to'g'ri kiriting!",
    success_send: "Ma'lumotlar muvaffaqiyatli saqlandi!",
    message_connect_tg_bot: `<span>Bot orqali xabarnoma olish uchun Botga ulanishingiz kerak.<a class="pui-text is-primary" href="https://t.me/smart_market_rs_bot" target="_blank"> Botga o'tish</a></span>`,
    input_tg_bot: `<span>Id olish uchun Bot ga ulanishingiz kerak.<a class="pui-text is-primary" href="https://t.me/smart_market_rs_bot" target="_blank"> Botga o'tish</a></span>`,
    add_new_adress: "+ Yangi manzil qo'shish",
    delivery_addresses: "Yetkazib berish hududlarini kiriting",
  },
  documents: {
    empty_contracts: "Shartnomalar mavjud emas",
    provider: "Ta'minotchi",
    contract_txt: "Shartnoma",
    signed_day: "Imzolangan sana",
    state: "Holati",
    count_txt: "Raqam",
    date_txt: "Sana",
    sum_txt: "Summa",
    seller_txt: "Sotuvchi",
    buyer_txt: "Xaridor",
    rejected: "Rad Etildi",
    view: "Ko'rib chiqilmoqda",
    created: "Qabul qilindi",
    in_process: "Imzolashda",
    in_process_confirmation:"Tasdiqlashda",
    canceled: "Bekor qilindi",
    rejected: "Rad etildi",
    full_signed: "To'liq imzolandi",
    sys_canceled: "Sistema rad etdi",
    cancel_view: "Bekor qilish",
    contract_is_canceled:"Sizning shartnomangiz bekor qilindi!",
    contract_is_canceled_message:"Sizning shartnomangiz bekor qilindi, istalgan vaqtda biz siz bilan yangi shartnoma tuzishga tayyormiz.",
    cancel_contract:"Shartnomani bekor qilish",
    cancel_contract_right:"Siz ushbu shartnomani nima uchun bekor qilmoqchisiz?",
    cancel_contract_reason:"Shartnomani bekor qilish sababi",
    search_by_number_placeholder: "Shartnoma raqam",
    seacrch_by_number_name:"Mahsulot nomi yoki shartnoma raqam",
    canceled_date:'Bekor qilingan sana',
    payment:"To'lov",
    cash_money:"Naqd pul",
    total_cash:"Jami summa",
    confirm_orers:"Sizning buyurtmangiz muvaffaqiyatli rasmiylashtirildi!",
    confirm_orers_text:"Sizning buyurtmangiz rasmiylashtirildi naqd pul bo‘limidan buyurtma chekini ko‘rishingiz mumkin!",

    order_is_canceled:"Sizning buyurtmangiz bekor qilindi!",
    order_is_canceled_message:"Sizning buyurtmangiz bekor qilindi, istalgan vaqtda qayta buyurtma berishingiz mumkin.",
    cancel_order:"Buyurtmani bekor qilish",
    cancel_order_right:"Siz ushbu buyurtmani nima uchun bekor qilmoqchisiz?",
    cancel_order_reason:"Buyurtmani bekor qilish sababi",

  },
  invoices: {
    empty_invoices: "Fakturalar mavjud emas",
    corporation_txt: "Korxona",
    customer: "Mijoz",
    helper_hokim: "Hokim yordamchisi dalolatnomasi",
    result: "Natija",
    search_by_number_placeholder: "Faktura raqamini kiriting",
  },
  notifications: {
    empty_notification: "Yangi xabarnomalar mavjud emas",
    empty_reads_notification: "O'qilgan xabarnomalar mavjud emas",
    newest: "Yangilari",
    olders: "Oldingilari",
  },
  tariffs:{
    name:"Tariflar",
    price:'Xizmat narxi:',
    info:'Xizmat haqida:',
    SILVER:{
      price:'Xaridor va Taʼminotchi o‘rtasidagi oferta summasining 1 foizi miqdorini tashkil qiladi lekin ushbu summa BHMning 1 foizidan kam bo‘lishi mumkin emas.',
      info:'Ushbu tarifda Taʼminotchiga takliflarini qabul qilish, moderatsiyadan o‘tkazish,  tovarlar va /yoki xizmatlar maʼlumotlar bazasiga kiritish), tovarlar va/yoki xizmatlarni realizatsiya qilish (yetkazib berish bo‘yicha shartnomalar loyihalari, hisob-fakturalar hamda tegishli xabarnomalarni  dasturiy tarzda tayyorlash), yetkazib berilgan tovarlar va/yoki xizmatlar uchun hisob-kitobni amalga oshirish (naqd/naqd pulsiz, bank kreditlari vositasida), realizatsiya natijalarini omborxona hisobida aks ettirish hamda talabni o‘rganish (oyiga 30 ta talab), Taʼminotchilar TOP ro‘yxatiga joylashtirish (oyiga 5 kun) bo‘yicha qo‘shimcha xizmatlar ko‘rsatiladi.'
    },
    GOLD:{
      price:'Xaridor va Taʼminotchi o‘rtasidagi oferta summasining 2 foizi miqdorini tashkil qiladi lekin ushbu summa BHMning 1 foizidan kam bo‘lishi mumkin emas.',
      info:'Ushbu tarifda Taʼminotchiga takliflarini qabul qilish, moderatsiyadan o‘tkazish,  tovarlar va /yoki xizmatlar maʼlumotlar bazasiga kiritish), tovarlar va/yoki xizmatlarni realizatsiya qilish (yetkazib berish bo‘yicha shartnomalar loyihalari, hisob-fakturalar hamda tegishli xabarnomalarni  dasturiy tarzda tayyorlash), yetkazib berilgan tovarlar va/yoki xizmatlar uchun hisob-kitobni amalga oshirish (naqd/naqd pulsiz, bank kreditlari vositasida), realizatsiya natijalarini omborxona hisobida aks ettirish hamda talabni o‘rganish (oyiga 60 ta talab), takliflarni Xaridorlarga birinchi navbatda yo‘llash (oyiga 60 ta taklif), moderatsiyadan navbatsiz o‘tkazish(oyiga 10 marta), Taʼminotchilar TOP ro‘yxatiga joylashtirish (oyiga 10 kun) bo‘yicha qo‘shimcha xizmatlar ko‘rsatiladi.'
    },
    PLATINUM:{
      price:'Xaridor va Taʼminotchi o‘rtasidagi oferta summasining 3 foizi miqdorini tashkil qiladi lekin ushbu summa BHMning 1 foizidan kam bo‘lishi mumkin emas.',
      info:'Ushbu tarifda Taʼminotchiga takliflarini qabul qilish, moderatsiyadan o‘tkazish,  tovarlar va /yoki xizmatlar maʼlumotlar bazasiga kiritish), tovarlar va/yoki xizmatlarni realizatsiya qilish (yetkazib berish bo‘yicha shartnomalar loyihalari, hisob-fakturalar hamda tegishli xabarnomalarni  dasturiy tarzda tayyorlash), yetkazib berilgan tovarlar va/yoki xizmatlar uchun hisob-kitobni amalga oshirish (naqd/naqd pulsiz, bank kreditlari vositasida), realizatsiya natijalarini omborxona hisobida aks ettirish hamda talabni o‘rganish (cheksiz), takliflarni Xaridorlarga birinchi navbatda yo‘llash (cheksiz), moderatsiyadan navbatsiz o‘tkazish(cheksiz), texnik yordamga navbatsiz bog‘lanish, buxgalteriya hisobini tizim bilan integratsiyalashtirish (alohida to‘lov evaziga), tovar va xizmatlar reklamasi (oyiga Ijrochining veb-sahifasida 2 marta, Telegram kanalida 1 marta), Taʼminotchilar TOP ro‘yxatiga joylashtirish (doimiy) bo‘yicha qo‘shimcha xizmatlar ko‘rsatiladi.'
    }
  }
};
