import Vue from "vue"

export default {
  namespaced: true,
  state: {
    addresses: []
  },
  mutations: {
    SET(state, addresses) {
      state.addresses = addresses
    },
    ADD(state, address) {
      state.addresses = [...state.addresses, address]
    },
    REMOVE(state, id) {
      state.addresses = state.addresses.filter(address => address.id !== id)
    },
    CLEAR(state) {
      state.addresses = []
    }
  },
  actions: {
    list({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        commit('CLEAR')
        if (rootState['Account'].account.token) {
          commit('SET_LOADING', true, {root: true})
          Vue.axios.get('api/v1/frontend/address').then((response) => {
            commit('SET', response.data.data)
            resolve()
          }).catch((error) => {
            reject(error.response)
          })
          .finally(()=>{
            commit('SET_LOADING', false, {root: true})
          })
        } else {
          resolve()
        }
      })
    },
    create({ commit }, address) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true, {root: true})
        Vue.axios.post('api/v1/frontend/address', address).then((response) => {
          commit('ADD', response.data.data)
          resolve(response.data.data)
        }).catch((error) => {
          reject(error.response)
        })
        .finally(()=>{
          commit('SET_LOADING', false, {root: true})
        })
      })
    },
    deleteAll({ commit, state }) {
      state.addresses.forEach(address => {
        Vue.axios.delete('api/v1/frontend/address', {params: {id: address.id}})
      })
    }
  },
}